import { Box } from "@mui/material";
import {
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button } from "../../common/Button/Button";
import CustomTabs, { ExtractKeyType } from "../../common/CustomTabs";
import { DownloadFileIcon } from "../../common/Icons/DownloadFileIcon";
import { MoveNextIcon } from "../../common/Icons/MoveNextIcon";
import { MovePrevIcon } from "../../common/Icons/MovePrevIcon";
import { PauseIcon } from "../../common/Icons/PauseIcon";
import { ShareFooterIcon } from "../../common/Icons/ShareFooterIcon";

// select jupyter theme
import "react-ipynb-renderer/dist/styles/default.css";

import "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import { useSessionStore } from "../../store/useSessionStore";
import {
  Container,
  ContentContainer,
  FooterBtnContainer,
  FooterContainer,
  HeaderContainer,
  ItemsContainer,
  MainContainer,
  SidebarContainer,
  StateContainer,
  StateParentContainer,
} from "./style";

import OutputsWrapper from "./components/OutputsWrapper";
import PlanWrapper from "./components/PlansWrapper";
import ReasoningWrapper from "./components/ReasoningsWrapper";
import TabWrapper from "./components/TabWrapper";

const tabs = [
  {
    key: "output",
    label: "Output",
  },
  {
    key: "plan",
    label: "Plan",
  },
  {
    key: "reasoning",
    label: "Reasoning",
  },
] as const;

type Props = { sidebar: boolean };

const BrightbotStateSidebar = ({ sidebar }: Props) => {
  const [activeTab, setActiveTab] =
    useState<ExtractKeyType<typeof tabs[number]>>("output");
  const [closedTabs, setClosedTabs] = useState<number[]>([]);

  const { outputs, plan, reasoning } = useSessionStore(
    ({ outputs, plan, reasoning }) => ({ outputs, plan, reasoning })
  );

  const scrollRef = useRef<HTMLDivElement | undefined>(null);

  // scroll to bottom of sidebar to display newest (output, plan, reasoning)
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [activeTab]);

  // handle the collapse of each tab
  const handleCollapse = useCallback(
    (index: number) => () => {
      setClosedTabs((prev) => {
        if (prev.includes(index)) {
          return prev.filter((tab) => tab !== index);
        }
        return [...prev, index];
      });
    },
    []
  );

  const displaySidebar = useMemo(
    () => ({ flexBasis: sidebar ? "43.25rem" : "0" }),
    [sidebar]
  );

  return (
    <Container sx={displaySidebar} id="sidebar-section">
      <SidebarContainer>
        <MainContainer>
          <HeaderContainer id="header">
            <Button>
              <MovePrevIcon />
            </Button>
            <Button>
              <PauseIcon className="pause" />
            </Button>
            <Button>
              <MoveNextIcon />
            </Button>
          </HeaderContainer>
          <ContentContainer id="content">
            <Box sx={{ overflow: "hidden", padding: "1.5rem" }}>
              <CustomTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Box>
            <StateParentContainer>
              <StateContainer>
                <ItemsContainer>
                  {activeTab === "output" && (
                    <TabWrapper list={outputs} title="outputs">
                      <OutputsWrapper
                        outputs={outputs}
                        closedTabs={closedTabs}
                        handleCollapse={handleCollapse}
                      />
                    </TabWrapper>
                  )}
                  {activeTab === "reasoning" && (
                    <TabWrapper list={reasoning} title="reasonings">
                      <ReasoningWrapper reasonings={reasoning} />
                    </TabWrapper>
                  )}
                  {activeTab === "plan" && (
                    <TabWrapper list={plan} title="plans">
                      <PlanWrapper plans={plan} />
                    </TabWrapper>
                  )}
                  <div
                    ref={scrollRef as LegacyRef<HTMLDivElement> | undefined}
                  ></div>
                </ItemsContainer>
              </StateContainer>
            </StateParentContainer>
          </ContentContainer>
          <FooterContainer id="footer">
            <Box display="flex" gap=".5rem">
              <FooterBtnContainer>
                <DownloadFileIcon sx={{ height: "1rem", width: "1rem" }} />
              </FooterBtnContainer>
              <FooterBtnContainer>
                <ShareFooterIcon sx={{ height: "1rem", width: "1rem" }} />
              </FooterBtnContainer>
            </Box>
          </FooterContainer>
        </MainContainer>
      </SidebarContainer>
    </Container>
  );
};

export default BrightbotStateSidebar;
