export type AnimationStylesTypes =
  | {
      displayContainer: {
        maxHeight: string;
        minHeight: number;
        padding: number;
        overflow: string;
      };
      rotateIcon: {
        rotate?: undefined;
      };
      displayTitleBorder: {
        border: string;
        paddingBottom: string;
      };
    }
  | {
      displayContainer: {
        maxHeight: string;
        minHeight?: undefined;
        padding?: undefined;
        overflow?: undefined;
      };
      rotateIcon: {
        rotate: string;
      };
      displayTitleBorder: {
        border?: undefined;
        paddingBottom?: undefined;
      };
    };

export enum agentTypes {
  dataVisualization = "data_visualization",
  dbt = "dbt",
  analyst = "analyst",
}
