import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { CodeProps } from "react-markdown/lib/ast-to-react";

const styles = {
  table: { width: "100%", borderCollapse: "collapse", margin: "20px 0" },
  th: {
    border: "1px solid #ddd",
    padding: "10px",
    backgroundColor: "#4CAF50",
    color: "white",
  },
  td: { border: "1px solid #ddd", padding: "10px" },
};

/**
 * Handling the display of code and table in markdown
 */

export const reactMarkdownComp = {
  code(props: React.PropsWithChildren<CodeProps>) {
    const { children, className, node, ...rest } = props;
    const match = /language-(\w+)/.exec(className?.split(" ")[0] || "");
    return match ? (
      <SyntaxHighlighter
        {...rest}
        PreTag="div"
        lineProps={{
          style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
        }}
        wrapLines={true}
        children={String(children).replace(/\n$/, "")}
        language={match[1]}
        style={monokai}
      />
    ) : (
      <code {...rest} className={className}>
        {children}
      </code>
    );
  },
  table: ({ children }: { children: React.ReactNode & React.ReactNode[] }) => (
    <table style={styles.table as any}>{children}</table>
  ),
  th: ({ children }: { children: React.ReactNode & React.ReactNode[] }) => (
    <th style={styles.th}>{children}</th>
  ),
  td: ({ children }: { children: React.ReactNode & React.ReactNode[] }) => (
    <td style={styles.td}>{children}</td>
  ),
};
