import { BasicPage } from "../common/BasicPage/BasicPage";
import { Typography } from "../common/Typography/Typography";
import { Box, Card, IconButton, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../generated";
import ProfileAvatarIcon from "../common/ProfileAvatarIcon/ProfileAvatarIcon";
import {
  ButtonPrimary,
  classes,
  StyledCircularProgress,
  StyledCloseButton,
  StyledConfirmationBox,
  StyledCorporateFare,
  StyledFormHelperText,
  StyledTypography,
  TypographyHeding,
} from "./style";
import useUserDetails from "./hooks/useUserDetails";
import { Formik } from "formik";
import useUserPassword from "./hooks/useUserPassword";
import UserDetailsEditForm from "./components/UserDetailsEditForm";
import { userDetailsSchema, userPasswordSchema } from "./yupSchema";
import { CloseIcon } from "../common/Icons/CloseIcon";

export type UserPasswordType = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type UserDetailsType = {
  firstName: string;
  lastName: string;
};

const initialPasswordValues: UserPasswordType = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const MyProfilePage = () => {
  const { palette } = useTheme();
  const { data } = useQuery(GetCurrentUserInfoDocument);
  const {
    toggleDetailsEdit,
    handleToggleDetailsEdit,
    submitUserDetails,
    loadingMutateDetails,
  } = useUserDetails();

  const emailAddress = data?.currentUser?.emailAddress;

  // contains useEffect
  const {
    togglePasswordEdit,
    loadingMutatePassword,
    handleTogglePassword,
    submitUserPassword,
    changePasswordHandler,
    changePassTimeLeft,
    changePassIsDisabled,
    displayEmailConfirmation,
    toggleDisplayEmailConfirmation,
  } = useUserPassword({ userEmail: emailAddress });

  const fullName = `${data?.currentUser?.firstName || ""} ${
    data?.currentUser?.lastName || ""
  }`;

  const organizationNames = data?.currentUser?.workspaces?.reduce(
    (accum, workspace) => {
      const orgName = workspace?.ownerOrganizationName;
      if (orgName) {
        if (accum === "") {
          return orgName;
        } else {
          return `${accum}, ${orgName}`;
        }
      }
      return accum;
    },
    ""
  );

  const userDetails: UserDetailsType = {
    firstName: data?.currentUser?.firstName || "",
    lastName: data?.currentUser?.lastName || "",
  };

  return (
    <BasicPage
      leftHeaderElements={<></>}
      content={
        <Card sx={{ pt: 4, pl: 6, pr: 6, pb: 8 }}>
          <Typography mb={4} variant="h6">
            Profile information
          </Typography>

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                NAME
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              {toggleDetailsEdit ? (
                <Formik
                  validationSchema={userDetailsSchema}
                  initialValues={userDetails}
                  onSubmit={submitUserDetails}
                >
                  <UserDetailsEditForm
                    loadingMutateDetails={loadingMutateDetails}
                    handleToggleDetailsEdit={handleToggleDetailsEdit}
                  />
                </Formik>
              ) : (
                <>
                  <ProfileAvatarIcon
                    name={fullName}
                    email={emailAddress}
                    width={24}
                    height={24}
                  />
                  <Typography variant="body2" ml={1}>
                    {fullName}
                  </Typography>

                  <Box ml={5}>
                    <IconButton
                      onClick={() => handleToggleDetailsEdit(true)}
                      size="small"
                      className="edit-button"
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                EMAIL
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" ml={1}>
                {emailAddress}
              </Typography>
            </Box>
          </Box>

          <Box mb={2}>
            <StyledTypography
              variant="overline"
              className={classes[classes.label]}
            >
              Password
            </StyledTypography>
          </Box>

          <Box display="flex" alignItems="center" mb={6}>
            <Typography variant="body2" ml={1}>
              *******
            </Typography>
            <Box ml={10}>
              <ButtonPrimary
                disabled={changePassIsDisabled || loadingMutatePassword}
                onClick={changePasswordHandler}
              >
                {loadingMutatePassword ? (
                  <>
                    <StyledCircularProgress size={16} /> Loading...
                  </>
                ) : (
                  "Change Password"
                )}
              </ButtonPrimary>
              {changePassIsDisabled && (
                <StyledFormHelperText>
                  Resend in {changePassTimeLeft}s
                </StyledFormHelperText>
              )}
            </Box>
          </Box>

          {displayEmailConfirmation && (
            <StyledConfirmationBox>
              <StyledCloseButton
                onClick={() => toggleDisplayEmailConfirmation(false)}
              >
                <CloseIcon />
              </StyledCloseButton>
              <Typography color={palette.success.darkest}>
                Change Password Email has been sent, to "{emailAddress}"
              </Typography>
            </StyledConfirmationBox>
          )}

          {/*
  // for handling change password in profile page but would comment it for later use
          <Box mb={6}>
            {togglePasswordEdit ? (
              <Formik
                validationSchema={userPasswordSchema}
                initialValues={initialPasswordValues}
                onSubmit={(values, actions) => {
                  console.log(values);
                }}
              >
                <UserPasswordEditForm
                  handleTogglePassword={handleTogglePassword}
                  loadingMutatePassword={loadingMutatePassword}
                />
              </Formik>
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body2" ml={1}>
                  *******
                </Typography>
                <Box ml={5}>
                  <IconButton
                    onClick={() => handleTogglePassword(true)}
                    size="small"
                    className="edit-button"
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
  */}

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                ORGANIZATIONS
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <StyledCorporateFare />
              <Typography variant="body2">{organizationNames}</Typography>
            </Box>
          </Box>

          {/*<Box mb={6}>*/}
          {/*  <Box mb={2}>*/}
          {/*    <Typography color={palette.gray.dark} variant="overline">*/}
          {/*      CONTACT*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Link fontWeight="bold" variant="body2" to="/#">*/}
          {/*      email@email.com*/}
          {/*    </Link>*/}
          {/*  </Box>*/}
          {/*</Box>*/}

          {/*<Box>*/}
          {/*  <Box mb={2}>*/}
          {/*    <Typography color={palette.gray.dark} variant="overline">*/}
          {/*      ACCOUNT CREATED*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Typography variant="body2">1/23/22</Typography>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Card>
      }
    />
  );
};
