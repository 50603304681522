import {
  styled,
  Box,
  Tab,
  Typography,
  TextField,
  Button,
  Divider,
  FormLabel,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { brighthiveTheme } from "../../../theme/theme";
export const ChatBotPage = styled("div")`
  position: relative;
`;

export const ChatBotSidebar = styled("div")`
  position: fixed;
  top: 48px;
  left: 260px;
  height: calc(100% - 48px);
  z-index: 99;
  border-right: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  width: 220px;
  overflow: auto;
  transition: all 0.3s;
  @media (max-width: 992px) {
    width: 180px;
  }
  animation: pulse 0.3s;
  @keyframes pulse {
    0% {
      left: 0;
    }
  }
  .main-sidenav-collapsed & {
    left: 48px;
  }
  .main-sidenav-opened & {
    left: 260px;
  }
`;

export const ChatContentWrapper = styled("div")`
  padding: 10px 50px 0 270px;
  height: calc(100vh - 48px);
  overflow: auto;
  transition: all 0.3s;
  @media (max-width: 992px) {
    padding: 10px 20px 0 200px;
  }
  &.sidebar-closed {
    padding: 10px 50px 0;
    @media (max-width: 992px) {
      padding: 10px 20px 0;
    }
    .page-footer {
      left: 260px;
    }
  }
  main {
    overflow: hidden;
    position: relative;
    padding: 10px 0 0;
    font-size: 14px;
  }
  .page-footer {
    position: fixed;
    left: 480px;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: all 0.3s;
    @media (max-width: 992px) {
      left: 439px;
    }
  }
`;
export const ButtonChatStart = styled(Button)`
  width: 100%;
  display: flex;
  margin: 5px 0 0;
  gap: 8px;
  justify-content: left;
  font-size: 14px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.accent.contrastText};
  &.active,
  &:hover {
    color: ${brighthiveTheme.palette.primary.darkest};
  }
  &:first-child {
    margin: 0;
  }
`;
export const ButtonHolder = styled(Box)`
  padding: 10px 0;
  border-bottom: 1px solid ${brighthiveTheme.palette.custom.contrastText};
`;
export const SquareIcon = styled(Box)`
  width: 36px;
  height: 36px;
  min-width: 36px;
  background: ${brighthiveTheme.palette.customRolled.light};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;
export const Text = styled("span")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const TypographyHeading = styled("h3")`
  color: ${brighthiveTheme.palette.accent.contrastText};
  font-weight: 500;
  font-size: 14px;
`;
export const YourChatList = styled("ul")`
  padding: 0 0 5px;
  margin: 0 -0.5rem 10px;
  &.no-border {
    border: none;
  }
`;
export const AccordionCustom = styled(Accordion)`
  border-bottom: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
  &:before {
    display: none;
  }
  &.no-border {
    border: none;
  }
  &.Mui-expanded {
    h3,
    > svg {
      color: ${brighthiveTheme.palette.primary.dark};
    }
  }
`;
export const AccordionSummaryCustom = styled(AccordionSummary)`
  min-height: 0 !important;
  > div {
    margin: 12px 0 !important;
  }
  &:hover {
    svg,
    h3 {
      color: ${brighthiveTheme.palette.primary.dark};
    }
  }
`;
export const YourChatListItem = styled("li")`
  margin: 0 0 5px;
  display: flex;
  padding: 5px 0;
  justify-content: space-between;
  position: relative;
  &:hover {
    background: ${brighthiveTheme.palette.settings.lighter};
    .active,
    .normal {
      color: ${brighthiveTheme.palette.primary.dark};
    }
    .drop {
      opacity: 1;
    }
  }
  &.active {
    background: ${brighthiveTheme.palette.settings.active};
    .active,
    .normal {
      color: ${brighthiveTheme.palette.primary.dark};
    }
    .drop {
      opacity: 1;
    }
  }
  &.loading {
    padding: 10px;
  }
`;
export const ButtonChatItem = styled("div")`
  display: flex;
  padding: 4px 8px;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
`;
export const FirstNameBox = styled("span")`
  text-transform: uppercase;
  min-width: 24px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: white;
  border-radius: 4px;
  font-size: 10px;
  transition: all 0.2s;
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  &.active {
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;
export const ButtonChatItemInfo = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex-grow: 1;
    font-size: 14px;
    &.add {
      font-size: 12px;
    }
  }
`;
export const ButtonEllipsisOpener = styled(Button)`
  padding: 0 5px;
  min-width: 30px;
  opacity: 0;
  color: ${brighthiveTheme.palette.gray.darkest};
  svg {
    width: 20px;
    transform: rotate(90deg);
  }
`;
export const DeleteDropDown = styled("div")`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 0;
  background: white;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
  min-width: 120px;
  border-radius: 4px;
  button {
    width: 100%;
    font-size: 13px;
    display: block;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    color: ${brighthiveTheme.palette.common.black};
    &.delete {
      color: ${brighthiveTheme.palette.error.dark};
    }
    svg {
      color: inherit;
      width: 12px;
      height: 12px;
    }
  }
`;
export const TypingArea = styled("div")`
  position: fixed;
  left: 480px;
  background: white;
  right: 0;
  padding: 5px 50px 20px;
  bottom: 37px;
  transition: all 0.3s;
  @media (max-width: 992px) {
    padding: 5px 24px 20px;
    left: 439px;
  }
  .sidebar-closed & {
    left: 260px;
  }
`;
export const PageContentHolder = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 0.5rem;
  padding-bottom: 104px;
  flex: 1 1;
  &.pb-6 {
    padding-bottom: 1.5rem;
  }
`;
export const ClickToContinue = styled("div")`
  border: 1px solid ${brighthiveTheme.palette.warning.main};
  background: ${brighthiveTheme.palette.warning.light};
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
export const LodingChat = styled("div")`
  color: ${brighthiveTheme.palette.accent.contrastText};
  line-height: 1.5rem;
  font-weight: 900;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }
`;
export const EditTextArea = styled("div")`
  background: rgba(58, 67, 105, 0.04);
  border-radius: 0.375rem;
  color: ${brighthiveTheme.palette.primary.dark};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
`;
export const EditTextAreaText = styled("div")`
  color: ${brighthiveTheme.palette.primary.dark};
  flex: 1 1;
  padding-left: 1rem;
`;
export const ButtonSaving = styled(Button)`
  color: ${brighthiveTheme.palette.primary.main};
  border-radius: 0;
  display: inline-flex;
  align-items: center;
  column-gap: 0.375rem;
  align-self: stretch;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
export const FilesListItem = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
  .file-holder {
    background: ${brighthiveTheme.palette.primary.dark};
    border-radius: 5px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    svg {
      color: ${brighthiveTheme.palette.common.white};
      width: 25px;
      height: 25px;
    }
  }
  .info {
    font-size: 14px;
    line-height: 20px;
    span {
      display: block;
      &.title {
        font-weight: 600;
        margin: 0 0 4px;
      }
    }
  }
  svg {
    color: ${brighthiveTheme.palette.grayAccent.main};
    width: 1.25rem;
    height: 1.25rem;
  }
`;
export const FilesListItemClose = styled("div")`
  display: flex;
  align-items: center;
  position: absolute;
  right: -6px;
  top: -6px;
  svg {
    color: ${brighthiveTheme.palette.grayAccent.main};
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }
`;
export const FilesListItemHolder = styled("div")`
  display: flex;
  align-items: center;
  min-width: 260px;
  background: ${brighthiveTheme.palette.common.white};
  border-radius: 5px;
  margin: 6px 0 4px;
  padding: 10px;
  position: relative;
`;
export const TypingAreaHolder = styled("div")`
  display: flex;
  flex-direction: column;
  background: rgba(58, 67, 105, 0.04);
  padding: 15px 20px 20px 20px;
  border-radius: 4px;
`;
export const UploadFileLists = styled("div")`
  display: flex;
  gap: 15px;
  overflow-x: auto;
  scrollbar-color: ${brighthiveTheme.palette.settings.active} transparent;
  &.not-allow {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const TypingForm = styled("form")`
  display: flex;
  margin-top: 0.5rem;
`;
export const TypingFormInput = styled("input")`
  background: none;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  padding-left: 10px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  width: 100%;
  display: block;
  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
`;
export const InputHolder = styled("div")`
  display: flex;
  z-index: 10;
  align-items: stretch;
  position: relative;
  flex-grow: 1;
  &.not-allow {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const ChatIconHolder = styled("div")`
  padding-left: 0.75rem;
  align-items: center;
  display: flex;
  left: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  pointer-events: none;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;
export const FileUploadLink = styled("div")`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin: 0.25rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.75rem;
  padding-right: 0.75rem;
  svg {
    width: 19px;
    height: 19px;
    color: ${brighthiveTheme.palette.accent.contrastText};
  }
`;
export const ButtonChatSubmit = styled(Button)`
  border: 1px solid transparent;
  color: ${brighthiveTheme.palette.primary.main};
  border-radius: 0;
  border-width: 1px 1px 1px 0;
  display: inline-flex;
  align-items: center;
  column-gap: 0.375rem;
  align-self: stretch;
  border-radius: 0 0.375rem 0.375rem 0;
  &.not-allow {
    opacity: 0.5;
    cursor: not-allowed;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${brighthiveTheme.palette.primary.main};
  }
`;
export const MainContent = styled("main")`
  padding: 10px 20px 50px !important;
`;
export const InfoMessageBot = styled("div")`
  background: ${brighthiveTheme.palette.error.light};
  color: ${brighthiveTheme.palette.error.dark};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem;
  border-radius: 0.375rem;
  display: inline-flex;
`;
export const BotHeaderRight = styled("div")`
  padding: 12px 50px;
  margin: 0 -50px 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  @media (max-width: 992px) {
    padding: 12px 20px;
    margin: 0 -20px 1.25rem;
  }
`;
export const BotHeaderHeading = styled("div")`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  .font-normal {
    font-weight: 400;
  }
`;
export const RadioLabel = styled("label")`
  align-items: center;
  display: inline-flex;
  gap: 15px;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
  }
  [type="radio"]:checked + div {
    border-color: ${brighthiveTheme.palette.primary.main};
    &:after {
      opacity: 1;
    }
  }
`;
export const FakeRadio = styled("div")`
  width: 20px;
  height: 20px;
  border: 2px solid ${brighthiveTheme.palette.gray.dark};
  border-radius: 50%;
  position: relative;
  transition: all 0.3s linear;
  &:after {
    content: "";
    background: ${brighthiveTheme.palette.primary.main};
    top: 4px;
    left: 4px;
    position: absolute;
    bottom: 4px;
    right: 4px;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s linear;
  }
`;
export const RadioButton = styled("div")`
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`;
export const LabelText = styled("span")`
  font-size: 18px;
`;
export const ThumbsHolder = styled("div")`
  gap: 0.5rem;
  flex-direction: row;
  display: flex;
  margin-top: 0.5rem;
  button {
    padding: 0.25rem;
    color: ${brighthiveTheme.palette.primary.dark};
    border-radius: 50%;
    border: 1px solid ${brighthiveTheme.palette.primary.dark};
    background: ${brighthiveTheme.palette.primary.active};
    &:hover {
      background: ${brighthiveTheme.palette.primary.dark};
      color: ${brighthiveTheme.palette.common.white};
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
export const MessageHolder = styled("div")`
  flex-direction: column;
  margin-bottom: 2rem;
  display: flex;
  &:hover {
    .edit-icon {
      opacity: 1 !important;
      &:hover {
        opacity: 0.9 !important;
      }
    }
  }
`;
export const MessageHolderRow = styled("div")`
  line-height: 1.5rem;
  flex-direction: row;
  display: flex;
`;
export const MessageWriter = styled("div")`
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  &.mt-1 {
    margin-top: 0.25rem;
  }
  .edit-icon,
  .close {
    color: ${brighthiveTheme.palette.accent.contrastText};
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    &.opacity-0 {
      opacity: 0;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .close {
    flex-shrink: 0;
    svg {
      color: ${brighthiveTheme.palette.accent.contrastText};
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
export const MessageWriterName = styled("div")`
  display: flex;
  color: ${brighthiveTheme.palette.accent.contrastText};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  flex-direction: column;
  width: 7rem;
  margin-top: 0.25rem;
`;
export const MessageContentArea = styled("div")`
  flex: 1 1;
`;
export const MessageContentDes = styled("div")`
  max-width: 65ch;
  color: ${brighthiveTheme.palette.accent.contrastText};
`;
export const ThumbHolder = styled("div")`
  padding-left: 148px;
  margin-top: 0.5rem;
`;
export const MessageEditHolder = styled("div")`
  width: 65ch;
  flex-direction: column;
  gap: 0.5rem;
  display: flex;
`;
export const ConfigAreaTitle = styled("div")`
  color: ${brighthiveTheme.palette.accent.contrastText};
  line-height: 1.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0 0 10px;
`;
export const ConfigChatList = styled("ul")`
  margin: 0 -0.5rem 10px;
`;
export const ConfigChatListItem = styled("li")`
  margin: 0 0 10px;
`;
export const ConfigChatListItemHolder = styled("div")`
  padding: 0.05rem;
  line-height: 1.5rem;
  border-radius: 0.375rem;
  column-gap: 0.75rem;
  cursor: pointer;
  display: flex;
  color: ${brighthiveTheme.palette.accent.contrastText};
  align-items: center;
  &:hover,
  &.active {
    color: ${brighthiveTheme.palette.primary.dark};
  }
  a {
    width: 1.25rem;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    &.edit {
      margin-left: auto;
      color: ${brighthiveTheme.palette.accent.contrastText};
    }
    &.delete {
      color: ${brighthiveTheme.palette.error.main};
    }
  }
`;
export const ConfigChatListItemInfo = styled("div")`
  flex-direction: column;
  display: flex;
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    &.des {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;
export const NewChatRightArea = styled("div")`
  align-items: stretch;
  flex-direction: row;
  align-self: stretch;
  flex: 1 1;
  display: flex;
`;
export const NewChatRightAreaSidebar = styled("div")`
  width: 18rem;
  padding-right: 1.5rem;
  border-right: 1px solid ${brighthiveTheme.palette.custom.contrastText};
`;
export const NewChatRightAreaContent = styled("div")`
  flex: 1 1;
`;
export const NewChatRightAreaContentHolder = styled("div")`
  padding-left: 1rem;
  padding-right: 1rem;
`;
export const SharelinkArea = styled("div")`
  border-radius: 0.375rem;
  display: flex;
  margin-bottom: 1rem;
`;
export const SharelinkAreaButton = styled("button")`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;
  background: none;
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  border-radius: 0.375rem 0 0 0.375rem;
  column-gap: 0.375rem;
  align-items: center;
  display: inline-flex;
  margin-left: -1px;
  position: relative;
  color: ${brighthiveTheme.palette.accent.contrastText};
  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.125rem;
    color: ${brighthiveTheme.palette.primary.main};
  }
`;
export const SharelinkArealink = styled("a")`
  line-height: 1.6rem;
  text-decoration-line: underline;
  font-size: 0.875rem;
  padding: 0.3rem 0.75rem;
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  border-left: 0;
  border-radius: 0 0.375rem 0.375rem 0;
  flex: 1 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
export const CustomLabelHeading = styled("label")`
  color: ${brighthiveTheme.palette.common.black};
  line-height: 1.5rem;
  font-weight: 500;
  flex-direction: column;
  margin-bottom: 0.5rem;
  display: flex;
  font-size: 16px;
`;
export const CustomLabeldes = styled("div")`
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 400;
  margin: 0 0 15px;
  font-size: 14x;
`;
export const RadioRow = styled("div")``;
export const CustomButtonUploadFile = styled("button")`
  display: flex;
  align-items: center;
  background: ${brighthiveTheme.palette.settings.lighter};
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -26px;
  &:hover {
    background: ${brighthiveTheme.palette.settings.light};
  }
  svg {
    width: 25px;
    height: 25px;
    color: ${brighthiveTheme.palette.accent.contrastText};
  }
`;
export const CustomButtonUploadFileHolder = styled("div")`
  position: relative;
  top: 5;
  padding: 10px 0 0;
`;
export const CustomButtonOpenClose = styled("button")`
  display: flex;
  padding: 0.3rem 0.4rem;
  border-radius: 8px;
  background-color: ${brighthiveTheme.palette.primary.outline};
  margin-left: 0.5rem;
  margin-right: 1rem;
`;
export const UploadFileList = styled("ul")`
  li {
    margin: 10px 0 0;
    display: flex;
    align-items: center;
    gap: 15px;
    .iconholder {
      display: flex;
      padding: 0.3rem 0.4rem;
      border-radius: 5px;
      background-color: ${brighthiveTheme.palette.primary.darkest};
      svg {
        color: white;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .info {
    span {
      display: block;
      font-size: 14px;
      &.title {
        font-weight: 600;
      }
    }
  }
  .close {
    margin: 0 0 0 auto;
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: ${brighthiveTheme.palette.error.main};
    }
  }
`;
export const CustomButtonTrash = styled("button")`
  align-self: flex-start;
  svg {
    width: 1rem;
    height: 1rem;
    color: ${brighthiveTheme.palette.error.main};
  }
`;
export const AutocompleteHolder = styled("div")`
  max-width: 42rem;
`;
export const NewChatBottomBlock = styled("div")`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  &.not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .tootls-block {
    padding: 0.5rem;
    border-radius: 0.375rem;
    flex-direction: column;
    max-width: 42rem;
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
    .tootls-row {
      display: flex;
      .open-close-btn {
        line-height: 1.5rem;
        font-size: 0.875rem;
        justify-content: space-between;
        align-items: center;
        margin-right: 0.5rem;
        display: flex;
        &.add {
          svg {
            color: ${brighthiveTheme.palette.primary.dark};
          }
        }
        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${brighthiveTheme.palette.accent.contrastText};
          &:hover {
            color: ${brighthiveTheme.palette.primary.dark};
          }
        }
      }
      .info {
        flex-direction: column;
        display: flex;
        flex: 1 1 auto;
        font-size: 0.875rem;
        line-height: 1.7142857;
        > div {
          max-width: 65ch;
        }
        p {
          color: ${brighthiveTheme.palette.accent.contrastText};
        }
      }
    }
    .formholder {
      margin-bottom: 0.5rem;
      flex-direction: column;
      display: flex;
      padding: 1rem 1.25rem 0;
      .fieldset {
        padding-top: 0.5rem;
        flex-direction: column;
        display: flex;
        label {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          line-height: 1.7142857;
        }
        input {
          border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
          border-radius: 0.375rem;
          font-size: 0.875rem;
          line-height: 1.7142857;
          outline: none;
          box-shadow: none;
          &:focus {
            border-color: ${brighthiveTheme.palette.primary.dark};
          }
        }
      }
    }
  }
`;
export const CustomTextarea = styled("textarea")`
  line-height: 1.5rem;
  font-size: 0.875rem;
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  width: 100%;
  display: block;
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: ${brighthiveTheme.palette.primary.dark};
  }
`;
export const CustomForm = styled("form")`
  flex-direction: column;
  display: flex;
  &.mb-6 {
    margin-bottom: 1.5rem;
  }
`;
export const NameSaveArea = styled("div")`
  flex-direction: row;
  display: flex;
  gap: 1rem;
`;
export const NameSaveAreaInner = styled("div")`
  flex-direction: row;
  display: flex;
  flex: 1 1;
`;
export const NameSaveAreaInputHolder = styled("div")`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  position: relative;
  input {
    border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    padding: 0.375rem 1rem;
    display: block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5rem;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: ${brighthiveTheme.palette.primary.dark};
    }
  }
`;
export const CustomSaveButton = styled("button")`
  padding: 0.5rem 1rem;
  background-color: ${brighthiveTheme.palette.primary.main};
  border-color: transparent;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  align-items: center;
  display: inline-flex;
  color: ${brighthiveTheme.palette.primary.darkest};
  transition: all 0.3s;
  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
  }
`;
export const SelectToolBox = styled("div")`
  max-width: 42rem;
  width: 100%;
`;
export const SelectToolHolder = styled("div")`
  position: relative;
  margin-top: 0.25rem;
  .dropdown {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background: ${brighthiveTheme.palette.common.white};
    border-radius: 0.375rem;
    overflow: auto;
    width: 100%;
    max-height: 15rem;
    margin-top: 0.25rem;
    z-index: 10;
    position: absolute;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    li {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      &.bg {
        background-color: ${brighthiveTheme.palette.primary.outline};
      }
    }
    .not-find {
      padding: 0.5rem 1rem;
      cursor: default;
      position: relative;
      color: ${brighthiveTheme.palette.accent.contrastText};
    }
  }
`;
export const SelectToolWrapper = styled("div")`
  line-height: 1.25rem;
  font-size: 0.875rem;
  display: flex;
  width: 100%;
  input {
    border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
    border-radius: 0.375rem;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    display: block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5rem;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: ${brighthiveTheme.palette.primary.dark};
    }
    &:focus + .arrow {
      transform: none;
    }
  }
  .combox-button {
    position: relative;
    flex-grow: 1;
  }
`;
export const SelectBoxIcon = styled("div")`
  padding-right: 0.5rem;
  align-items: center;
  right: 0px;
  display: flex;
  top: 0px;
  bottom: 0px;
  position: absolute;
  pointer-events: none;
  transform: rotateX(-550deg);
  svg {
    color: ${brighthiveTheme.palette.accent.contrastText};
  }
`;
export const MessageShowArea = styled("div")`
  font-size: 14px;
  line-height: 1.75;
  max-width: 65ch;
  color: ${brighthiveTheme.palette.common.black};
`;
export const MesssageEditArea = styled("div")`
  display: grid;
  width: 100%;
  &.stringeditor {
    color: ${brighthiveTheme.palette.common.black};
    line-height: 1.5;
    background: ${brighthiveTheme.palette.common.white};
  }
  &.overflow {
    max-height: 20rem;
    overflow: auto;
  }
  .common-class {
    font-size: 0.875rem;
    line-height: 1.25rem;
    grid-row: 1;
    grid-column: 1;
    margin: 0px;
    resize: none;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border: 1px solid ${brighthiveTheme.palette.gray.contrastText};
    background: ${brighthiveTheme.palette.common.white};
    padding: 0.25rem 0.5rem;
    box-shadow: none;
  }
  .event {
    user-select: none;
    pointer-events: none;
  }
`;
export const MesssageEdittextarea = styled("textarea")`
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  border-radius: 0.25rem;
  color: transparent;
  caret-color: rgba(0, 0, 0, 0.9);
  &:focus {
    border-color: ${brighthiveTheme.palette.primary.dark};
  }
`;
export const SearchListLi = styled("li")`
  margin: 0 8px 8px;
  position: relative;
  > svg {
    position: absolute;
    left: 10px;
    top: 7px;
    width: 1.042rem;
    height: 1.042rem;
    pointer-events: none;
  }
  > div {
    width: 100%;
  }
  input {
    padding: 6px 6px 6px 32px;
    font-size: 14px;
    box-shadow: none !important;
  }
`;
export const ChatUseSpan = styled("span")`
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin-right: 0.5rem;
`;
export const ChatRetrieverSpan = styled("span")`
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  align-items: center;
  display: inline-flex;
  margin-right: 0.5rem;
  top: -1px;
  position: relative;
`;
export const ChatFileNameHolder = styled("div")`
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
export const ChatFileTableHolder = styled("div")`
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  border-radius: 0.25rem;
  display: inline-block;
`;
export const ChatFileTable = styled("table")``;
export const ChatFileTableTd = styled("td")`
  padding: 0.25rem 0.75rem;
  &:first-child {
    font-weight: 500;
    border-right: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  }
  &.border-transparent {
    border-top-width: 1px;
    border-color: transparent;
  }
  &.border-transparent {
    border-top-width: 1px;
    border-color: transparent;
  }
  &.border-top {
    border-top-width: 1px;
    border-color: ${brighthiveTheme.palette.custom.contrastText};
  }
`;
export const ResultsFromName = styled("span")`
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  border-right: none;
  border-radius: 5px 0 0 5px;
  padding: 0.25rem 0.5rem;
  align-items: center;
  display: inline-flex;
  top: -1px;
  position: relative;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
export const ResultsDropLink = styled("span")`
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
  border-radius: 0 5px 5px 0;
  padding: 0.25rem;
  align-items: center;
  display: inline-flex;
  top: 0.25rem;
  position: relative;
  cursor: pointer;
  &.mb-2 {
    margin-bottom: 0.5rem;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
  svg {
    width: 1.25rem;
    height: 1.25rem;
    opacity: 0.7;
    &.active {
      transform: rotate(180deg);
    }
  }
`;
export const MessageEditFileHolder = styled("div")`
  flex-direction: column;
  display: flex;
  .top-row {
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    display: flex;
  }
  .use-text {
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
  select {
    border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.25rem 1.8rem 0.25rem 0.5rem;
    outline: none;
    box-shadow: none;
    text-transform: capitalize;

    &:focus {
      border-color: ${brighthiveTheme.palette.primary.dark};
    }
  }
  .icon-trash {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    color: ${brighthiveTheme.palette.error.main};
  }
`;
export const AddCallBtn = styled("div")`
  display: flex;
  opacity: 0.9;
  padding-left: 0.5rem;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
  color: ${brighthiveTheme.palette.accent.contrastText};
  &:hover {
    opacity: 1;
  }
  svg {
    width: 1rem;
    height: 1rem;
    color: ${brighthiveTheme.palette.accent.contrastText};
  }
`;
export const AddArgumentBtn = styled("td")`
  display: flex;
  opacity: 0.9;
  padding-left: 0.5rem;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
  color: ${brighthiveTheme.palette.accent.contrastText};
  &:hover {
    opacity: 1;
  }
  svg {
    width: 1rem;
    height: 1rem;
    color: ${brighthiveTheme.palette.accent.contrastText};
  }
  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }
`;
export const AddNewBox = styled("div")`
  border-radius: 0.25rem;
  border: 1px solid ${brighthiveTheme.palette.primary.main};
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  .input-holder {
    display: flex;
    align-items: center;
    svg {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin-left: 0.5rem;
      color: ${brighthiveTheme.palette.error.main};
    }
  }
  table {
    width: 100%;
    td {
      padding: 0.5rem;
      display: table-cell;
      &:first-child {
        font-size: 0.875rem;
        line-height: 1.25rem;
        min-width: 128px;
        width: 0px;
      }
      &.border-top {
        border-top: 1px solid ${brighthiveTheme.palette.custom.contrastText};
      }
    }
    input {
      border: 1px solid ${brighthiveTheme.palette.custom.contrastText};
      border-radius: 0;
      font-size: 0.875rem;
      line-height: 1.7142857;
      outline: none;
      box-shadow: none;
      padding: 2px 10px;
      &:focus {
        border-color: ${brighthiveTheme.palette.primary.dark};
      }
    }
  }
`;
