import { Box, styled } from "@mui/material";
import SkeletonComp from "../../SkeletonComp";

export const MainContainer = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
}));

export const PromptsContainer = styled(Box)(() => ({
  display: "flex",
  maxWidth: "890px",
  width: "100%",
  margin: "0 auto",
  columnGap: "1rem",
  marginBottom: "3rem",
}));

export const PromptContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.primary.lighter,
  padding: "1.5rem 1rem",
  height: "9.049rem",
  width: "calc(33.333% - 11px)",
}));

export const InputContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1rem",
  height: "4.063rem",
}));

export const HeaderSkeleton = styled(SkeletonComp)(() => ({
  marginBottom: "1rem",
  fontSize: "0.875rem",
}));

export const DescriptionSkeleton = styled(SkeletonComp)(() => ({
  fontSize: "0.875rem",
}));

export const InputSkeleton = styled(SkeletonComp)(() => ({
  maxWidth: "890px",
  width: "100%",
  height: "100%",
}));
