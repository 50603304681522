import { Box } from "@mui/material";
import { size } from "lodash";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-react";
import { reactMarkdownComp } from "../../../../../../Constants/reactMarkdownComp";
import useTab from "../../../../hooks/useTab";
import {
  ItemContentContainer,
  ItemWrapper,
  StyledAGGrid,
} from "../../../../style";
import TitleWrapper from "../../../TitleWrapper";

type Props = {
  output: any;
  index: number;
  isClosed: boolean;
  handleCollapse: (index: number) => () => void;
};

const RetrevialBlock = ({ output, index, isClosed, handleCollapse }: Props) => {
  const { animationStyles } = useTab({ isClosed });

  return (
    <ItemWrapper>
      <TitleWrapper
        title="Retrieval"
        handleCollapse={handleCollapse}
        index={index}
        animationStyles={animationStyles}
      />
      <ItemContentContainer sx={animationStyles.displayContainer}>
        <Box>
          <ReactMarkdown
            children={output?.summary}
            //remarkPlugins={[remarkGfm]} removed because it was causing the markdown to not render properly
            rehypePlugins={[rehypeRaw] as any}
            components={reactMarkdownComp}
          />
        </Box>
        {size(output?.table) > 0 && (
          <Box
            mt={2}
            sx={{ maxHeight: "25vh" }}
            onClick={(e) => e.stopPropagation()}
          >
            <StyledAGGrid
              className="ag-theme-material"
              suppressContextMenu
              suppressMovableColumns
              suppressDragLeaveHidesColumns
              pagination
              headerHeight={40}
              rowHeight={40}
              domLayout="autoHeight"
              rowData={output?.table}
              columnDefs={Object.keys(output?.table[0]).map((key) => ({
                field: key,
              }))}
            />
          </Box>
        )}
      </ItemContentContainer>
    </ItemWrapper>
  );
};

export default RetrevialBlock;
