import { Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-react";
import remarkGfm from "remark-gfm";
import { reactMarkdownComp } from "../../../../Constants/reactMarkdownComp";
import { ItemContentContainer, ItemWrapper } from "../../style";

type Props = {
  plans: any[];
};

const PlansWrapper = ({ plans }: Props) => {
  return (
    <>
      {plans?.map((plan, index) => (
        <ItemWrapper key={`plan-${index}`}>
          <ItemContentContainer>
            <Box>
              <ReactMarkdown
                children={plan}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw] as any}
                components={reactMarkdownComp}
              />
            </Box>
          </ItemContentContainer>
        </ItemWrapper>
      ))}
    </>
  );
};

export default PlansWrapper;
