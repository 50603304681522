import { Box, styled } from "@mui/material";
import { BrightBotSmileIcon } from "../../../../common/Icons/BrightBotSmileIcon";

export const MainContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40vh",
  textAlign: "center",
});

export const StyledBrightbotSmileIcon = styled(BrightBotSmileIcon)({
  fontSize: "10rem",
});
