import {
  styled,
  Box,
  Tab,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { Tabs } from "../common/Tabs/Tabs";
import { brighthiveTheme } from "../theme/theme";

export const ProfilePage = styled(Box)`
  height: 100%;

  .page-title {
    background-color: ${brighthiveTheme.palette.background.default};

    > .MuiDivider-root {
      display: block !important;
      margin-bottom: 7px;
      margin-top: 1rem;
    }
  }

  .MuiDivider-root {
    display: none;
  }
`;

export const AccountAdminHolder = styled("div")`
  background-color: ${brighthiveTheme.palette.background.default};
`;
export const TypographyHeding = styled(Typography)`
  margin-bottom: 18px;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  &.small {
    border: none;
    padding: 0;
  }
`;
export const AccountAdminFrame = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  @media (max-width: 1200px) {
    display: block;
  }
`;
export const AccountAdmincontent = styled("div")`
  width: 100%;
`;
export const AccountAdminsidebar = styled("div")`
  min-width: 390px;
  max-width: 390px;
  @media (max-width: 1200px) {
    min-width: 100%;
    max-width: 100%;
    margin-top: 1.5rem;
  }
`;
export const ContentBox = styled("div")`
  width: 100%;
  box-sizing: border-box;
  border: 0.0625rem solid ${brighthiveTheme.palette.action.disabledBackground};
  border-radius: 0.375rem;
  padding: 16px;
  margin-top: 1.5rem;
  background-color: ${brighthiveTheme.palette.common.white};
  &:first-child {
    margin-top: 0;
  }
`;
export const AccountAdminHead = styled("div")`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0 -16px 15px;
  padding: 0 16px 10px;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  h6 {
    margin: 0;
  }
  &.add {
    h6 {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;
export const AccountAdmintitle = styled("p")`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.accent.contrastText};
  &.add {
    font-size: 12px;
    line-height: 16px;
  }
  &.more {
    font-weight: 400;
  }
  b {
    color: black;
  }
`;
export const BotIconText = styled("div")`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  gap: 6px;
  color: ${brighthiveTheme.palette.accent.contrastText};
  svg {
    path {
      fill: rgba(15, 150, 200, 1);
    }
  }
`;
export const TypographyHedingBig = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 5px;
`;
export const TableUsage = styled("div")`
  border: 0.0625rem solid ${brighthiveTheme.palette.action.disabledBackground};
  border-radius: 0.375rem;
  overflow: hidden;
  margin: 15px 0 0;
  table {
    width: 100%;
    th {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      text-align: left;
      padding: 10px;
      border-left: 0.0625rem solid
        ${brighthiveTheme.palette.action.disabledBackground};
      &:first-child {
        border-left: none;
      }
    }
    td {
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 10px;
      color: ${brighthiveTheme.palette.accent.contrastText};
      border-top: 0.0625rem solid
        ${brighthiveTheme.palette.action.disabledBackground};
      border-left: 0.0625rem solid
        ${brighthiveTheme.palette.action.disabledBackground};
      &:first-child {
        font-weight: 500;
        border-left: none;
        color: ${brighthiveTheme.palette.common.black};
      }
    }
  }
`;
