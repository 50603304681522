import React, { useMemo } from "react";

type Props = {
  isClosed: boolean;
};

const useTab = ({ isClosed }: Props) => {
  const animationStyles = useMemo(
    () =>
      isClosed
        ? {
            displayContainer: {
              maxHeight: "0px",
              minHeight: 0,
              padding: 0,
              overflow: "hidden",
            },
            rotateIcon: {},
            displayTitleBorder: { border: "none", paddingBottom: "0" },
          }
        : {
            displayContainer: { maxHeight: "50vh" },
            rotateIcon: { rotate: "180deg" },
            displayTitleBorder: {},
          },
    [isClosed]
  );

  return {
    animationStyles,
  };
};

export default useTab;
