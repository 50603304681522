import { useQuery } from "@apollo/client";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { size } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AttachmentIcon } from "../../common/Icons/AttachmentIcon";
import { PaperPlaneIcon } from "../../common/Icons/PaperPlaneIcon";
import { GetCurrentUserInfoDocument } from "../../generated";
import { ChatBotWidget } from "../ChatBotWidget/ChatBotWidget";
import { ChatRcvBotWidget } from "../ChatRcvBotWidget/ChatRcvBotWidget";
import { ChatSentBotWidget } from "../ChatSentBotWidget/ChatSentBotWidget";
import { CircularProgress, useTheme } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-react";
import remarkGfm from "remark-gfm";
import chatApi from "../../api/chatApi";
import { reactMarkdownComp } from "../../Constants/reactMarkdownComp";
import { useBrightbotStore } from "../../store/useBrightbotStore";
import { useSessionStore } from "../../store/useSessionStore";
import {
  ActionsWrapper,
  ButtonAttachment,
  ButtonSubmit,
  ChatBotWrapper,
  EditorWrapper,
  FormField,
  FormWrapper,
  MessagesWrapper,
  WidgetCol,
  WidgetsWrapper,
} from "../style";
import { SuggestionBox } from "../SuggestionBox";
import {
  EditorContainer,
  LoadingContainer,
  MainContainer,
  StyledStopIcon,
  SubMainContainer,
} from "./style";
import { streamErrorTypes, streamEventType, userType } from "../types";

let controller: any;

const initialStreamError = { error: "", message: "" };

export const ChatBot = ({
  showSuggestion,
  threadData,
  setRefetch,
  setSidebar,
}: any) => {
  const navigate = useNavigate();
  const { workspaceId, threadId } = useParams();
  const [messages, setMessages] = useState<any>([]);
  const [streaming, setStream] = useState<any>();
  const [messageValue, setMessageValue] = useState<any>("");
  const [activeRunId, setActiveRunId] = useState();
  const [loading, setLoading] = useState(false);
  const { palette } = useTheme();
  const { data } = useQuery(GetCurrentUserInfoDocument);

  const ref = useRef<HTMLButtonElement | null>(null);

  const [thread, setThread]: any = useState();
  const { setActiveAssistant, setAssistants, activeAssistant } =
    useBrightbotStore((state) => state);

  const { pathname, state: locationState } = useLocation();
  const externalState = locationState as { brightbotText?: string };

  const [streamMessage, setStreamMessage] = useState("");

  const { fetchNewThread, updateLogicState } = useSessionStore(
    (state) => state
  );

  const streamContent = useCallback(async (newMessage: any) => {
    setMessages((prev: any) => [...prev, { ...newMessage, content: "" }]);
    for (let i = 0; i < newMessage.content.length; i++) {
      await new Promise((resolve) => setTimeout(resolve, 50));
      setMessages((prev: any) =>
        prev.map((message: any) => {
          if (message.id === newMessage.id)
            return {
              ...message,
              content: message.content + newMessage.content[i],
            };

          return message;
        })
      );
    }
  }, []);

  const newMessagesStreamerHandler = useCallback(async (messages: any) => {
    for (const message of messages) {
      if (message.type === userType.ai) {
        await streamContent(message);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
  }, []);

  const streamHandler = async (
    id: string | undefined,
    data: any,
    message: string
  ) => {
    controller = new AbortController();
    const signal = controller?.signal;
    let newMessage = "";
    let streamError = initialStreamError;

    await fetchEventSource(
      `${process.env.REACT_APP_BASE_URL}/threads/${id}/runs/stream`,
      {
        signal: signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": process.env.REACT_APP_BRIGHTIBOT_API_KEY || "",
        },
        body: JSON.stringify({
          assistant_id: activeAssistant?.assistant_id,
          ...(messages.length === 0 && {
            metadata: { Title: message, Author: userName },
          }),
          input: {
            session_info: JSON.stringify({ workspace_id: workspaceId }),
            messages: [data[size(data) - 1]], // send only the latest message
          },
          user_info: userId,
          stream_mode: "events",
        }),
        openWhenHidden: true,
        async onopen(res) {
          if (res.status === 200) {
            setStream(true);
            setStreamMessage("");
          }
        },
        onmessage(msg: any) {
          let dt;
          if (msg?.data) {
            dt = JSON.parse(msg.data);
          }

          if (msg.event === streamEventType.metadata) {
            setActiveRunId(dt?.run_id);
          }

          if (msg.event === streamEventType.error) {
            streamError = { error: dt?.error, message: dt?.message };
          }

          if (msg.event === streamEventType.events) {
            // if data coming from "on_chat_model_stream" and the content is of type string then we append it
            if (
              dt.event === streamEventType.chatModelStream &&
              (typeof dt?.data?.chunk?.content === "string" ||
                dt?.data?.chunk?.content instanceof String)
            ) {
              const output = newMessage + dt?.data?.chunk?.content;

              setStreamMessage(output);
              newMessage = output;
            }
          } else if (msg.event === streamEventType.end) {
            setMessages([
              ...data,
              {
                content: newMessage,
                additional_kwargs: {},
                response_metadata: {},
                type: "ai",
                example: false,
              },
            ]);
            setStream(false);
          }
        },
        // on connection close we append the new message coming from brightbot
        // then send API call to get all output, plan and reasoning, open the sidebar and add them in the sidebar
        // sidebar won't open if there was a user interrup error
        onclose() {
          setStream(false);
          setActiveRunId(undefined);
          chatApi.get(`/threads/${id}/state`).then((res) => {
            const { outputs, plan, reasoning, messages } = res?.data?.values;
            updateLogicState(outputs, plan, reasoning);
            newMessagesStreamerHandler(messages.splice(-1));
            if (streamError.error === streamErrorTypes.UserInterrupt) {
              streamError = initialStreamError;
              return;
            }
            setSidebar(true);
          });
        },
        onerror(err) {
          throw new Error(err);
        },
      }
    );
  };

  const userName = `${data?.currentUser?.firstName.split(" ").join("-") || ""}`;
  const userId = data?.currentUser.id;

  const chatCreate = async (data: any, message: string) => {
    let id = threadId;
    if (!thread) id = await createSession(message);

    await streamHandler(id, data, message);
    //await waitForRun(id, data, message);
  };

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();

      if (!e.currentTarget.message.value) return;

      const data = e.currentTarget.message.value;

      if (!data) return;

      const dataMessage = [
        ...messages,
        {
          content: data,
          additional_kwargs: {},
          response_metadata: {},
          type: userType.human,
          name: userName,
          example: false,
        },
      ];
      setMessageValue("");
      setMessages(dataMessage);
      e?.currentTarget?.reset();

      await chatCreate(dataMessage, data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleWidgetSubmit = (text: string) => {
    setMessageValue(text);
    const timeout = setTimeout(() => {
      if (ref.current) {
        ref.current.click();
        setMessageValue("");
      } else {
        console.error("Ref is not initialized at handleWidgetSubmit");
      }
    }, 0);

    return timeout;
  };

  useEffect(() => {
    return () => {
      controller?.abort();
    };
  }, []);

  const createSession = async (message: string) => {
    try {
      const res = await chatApi.post("/threads", {
        metadata: {
          user_id: userId,
          workspace_id: workspaceId,
          Title: message,
          Author: userName,
        },
      });

      const id = res.data.thread_id;
      setThread(id);
      if (setRefetch) {
        setRefetch((prev: any) => !prev);
        navigate(`/workspace/${workspaceId}/brightbot/${id}`);
      }
      return id;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (threadId && thread !== threadId) {
      setThread(threadId);
    }
  }, [threadId, thread, pathname, workspaceId]);

  useEffect(() => {
    if (pathname === `/workspace/${workspaceId}/brightbot`) {
      setThread();
      setMessages([]);
    }
  }, [pathname, workspaceId]);

  useEffect(() => {
    chatApi
      .post("/assistants/search", { limit: 1000, offset: 0 })
      .then((res) => {
        // make the superduper agent the default active agent
        setActiveAssistant(
          res.data.find((data: any) => data?.graph_id === "superduper_agent")
        );
        setAssistants(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (threadId && thread !== threadId) {
      // on going from one thread to another, clear the stream message and set stream animation chat to false
      setStream(false);
      setStreamMessage("");
      setLoading(true);
      setMessages([]);
      chatApi
        .get(`/threads/${threadId}/state`)
        .then((res) => {
          setMessages(res?.data?.values?.messages || []);
          fetchNewThread(
            threadId,
            res?.data?.metadata?.assistant_id,
            res?.data?.values
          );
        })
        .finally(() => setLoading(false));
    }
    // close sidebar if we going to create new session
    if (!threadId) {
      updateLogicState([], [], []);
      setSidebar(false);
    }
  }, [threadId]);

  // add data from external widget prompts
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (externalState?.brightbotText) {
      timeout = handleWidgetSubmit(externalState.brightbotText);
    }

    return () => clearTimeout(timeout);
  }, [externalState?.brightbotText]);

  const abortMessageHandler = async () => {
    if (!activeRunId) return;
    try {
      await chatApi.post(
        `/threads/${thread}/runs/${activeRunId}/cancel?wait=false&action=interrupt`
      );
    } catch (err) {
      console.log(err);
    } finally {
      setStream(false);
      setActiveRunId(undefined);
    }
  };

  return (
    <ChatBotWrapper className="ChatBot">
      <MainContainer>
        <SubMainContainer>
          {!loading ? (
            <>
              {size(messages) < 1 && !externalState && (
                <WidgetsWrapper>
                  <WidgetCol>
                    <ChatBotWidget
                      title="Draft a data strategy"
                      description="Look at the documents provided in the workspace and write a data strategy document for me."
                      onClick={() =>
                        handleWidgetSubmit(
                          "Look at the documents provided in the workspace and write a data strategy document for me."
                        )
                      }
                    />
                  </WidgetCol>
                  <WidgetCol>
                    <ChatBotWidget
                      title="Extract information for docs"
                      description="Take the document that I upload and extract key terms based on the category of teams that I specify."
                      onClick={() =>
                        handleWidgetSubmit(
                          "Take the document that I upload and extract key terms based on the category of teams that I specify."
                        )
                      }
                    />
                  </WidgetCol>
                  <WidgetCol>
                    <ChatBotWidget
                      title="Quick insights from my data"
                      description="Look at my most recently used data asset and tell me some interesting and novel insights."
                      onClick={() =>
                        handleWidgetSubmit(
                          "Look at my most recently used data asset and tell me some interesting and novel insights."
                        )
                      }
                    />
                  </WidgetCol>
                </WidgetsWrapper>
              )}

              <MessagesWrapper>
                {messages.map((message: any, index: number) => (
                  <>
                    {message?.type === userType.human && (
                      <ChatSentBotWidget key={index}>
                        <ReactMarkdown
                          className="line-break"
                          children={message.content}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw] as any}
                          components={reactMarkdownComp}
                        />
                      </ChatSentBotWidget>
                    )}
                    {message?.type === userType.ai && (
                      <ChatRcvBotWidget key={index}>
                        <ReactMarkdown
                          children={message.content}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw] as any}
                          components={reactMarkdownComp}
                        />
                      </ChatRcvBotWidget>
                    )}
                  </>
                ))}
                {streaming && (
                  <ChatRcvBotWidget streaming={true}>
                    Brightbot Is Thinking
                  </ChatRcvBotWidget>
                )}
                {/*
                // commented for demo purpose
                streaming && (
                  <ChatRcvBotWidget>
                    <ReactMarkdown
                      children={streamMessage}
                      //remarkPlugins={[remarkGfm]} removed because it was causing the markdown to not render properly
                      rehypePlugins={[rehypeRaw] as any}
                      components={reactMarkdownComp}
                    />
                  </ChatRcvBotWidget>
                )*/}
                {showSuggestion && <SuggestionBox />}
              </MessagesWrapper>
            </>
          ) : (
            <LoadingContainer>
              <CircularProgress sx={{ color: palette.primary.darkest }} />
            </LoadingContainer>
          )}
        </SubMainContainer>
      </MainContainer>
      <EditorContainer>
        <EditorWrapper>
          <form onSubmit={onSubmit}>
            <FormWrapper>
              <FormField
                id="chatId"
                placeholder="Ask something&hellip;"
                name="message"
                value={messageValue}
                onChange={(e) => setMessageValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    e.preventDefault();
                    ref?.current?.click();
                    setMessageValue("");
                  }
                }}
                multiline
              />
              <ActionsWrapper>
                <ButtonAttachment>
                  <input type="file" id="attachement" name="attachement" />
                  <label htmlFor="attachement">
                    <AttachmentIcon />
                  </label>
                </ButtonAttachment>
                <ButtonSubmit
                  disabled={!activeAssistant}
                  type="submit"
                  ref={ref}
                >
                  {streaming ? (
                    <StyledStopIcon onClick={abortMessageHandler} />
                  ) : (
                    <PaperPlaneIcon />
                  )}
                </ButtonSubmit>
              </ActionsWrapper>
            </FormWrapper>
          </form>
        </EditorWrapper>
      </EditorContainer>
    </ChatBotWrapper>
  );
};
