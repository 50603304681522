import ReactMarkdown from "react-markdown";

import { Box, useTheme } from "@mui/material";
import rehypeRaw from "rehype-react";
import { reactMarkdownComp } from "../../../../../../Constants/reactMarkdownComp";
import useTab from "../../../../hooks/useTab";
import { ItemContentContainer, ItemWrapper } from "../../../../style";
import TitleWrapper from "../../../TitleWrapper";
import { StyledIframe, StyledIFrameContainer } from "./style";

type Props = {
  output: any;
  index: number;
  isClosed: boolean;
  handleCollapse: (index: number) => () => void;
};

const VisualizationBlock = ({
  output,
  index,
  isClosed,
  handleCollapse,
}: Props) => {
  const { palette } = useTheme();

  const { animationStyles } = useTab({ isClosed });
  return (
    <ItemWrapper>
      <TitleWrapper
        title="Vizualization"
        handleCollapse={handleCollapse}
        index={index}
        animationStyles={animationStyles}
      />
      <ItemContentContainer sx={animationStyles.displayContainer}>
        {output?.markdown && (
          <Box>
            <ReactMarkdown
              children={output?.markdown}
              //remarkPlugins={[remarkGfm]} removed because it was causing the markdown to not render properly
              rehypePlugins={[rehypeRaw] as any}
              components={reactMarkdownComp}
            />
          </Box>
        )}
        {output?.url && (
          <StyledIFrameContainer onClick={(e) => e.stopPropagation()}>
            <StyledIframe loading="lazy" src={output?.url}></StyledIframe>
          </StyledIFrameContainer>
        )}
      </ItemContentContainer>
    </ItemWrapper>
  );
};

export default VisualizationBlock;
