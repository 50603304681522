import { useState } from "react";
import { Grid } from "@mui/material";

import { WorkspaceDetail } from "./WorkspaceDetail";
import { WorkspaceDetailEdit } from "./WorkspaceDetailEdit";
import { AdditionalDetails } from "./AdditionalDetails";
import { GetGovernanceInfoDocument } from "../../generated";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SidebarPolicyOverview } from "./style";

export const GovernanceOverview = () => {
  const { workspaceId } = useParams();
  const { data, refetch } = useQuery(GetGovernanceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const [editMode, setEditMode] = useState(false);
  if (data) {
    return (
      <Grid container spacing={8}>
        <Grid item xs>
          {editMode ? (
            <WorkspaceDetailEdit
              data={data}
              onSave={() => {
                setEditMode(false);
                refetch();
              }}
              onCancel={() => setEditMode(false)}
            />
          ) : (
            <WorkspaceDetail data={data} onEdit={() => setEditMode(true)} />
          )}
        </Grid>
        <SidebarPolicyOverview>
          <AdditionalDetails mb={"1.5rem"} />
        </SidebarPolicyOverview>
      </Grid>
    );
  }
  return null;
};
