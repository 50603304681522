import { useMemo } from "react";
import { DropzoneState } from "react-dropzone";
import { PaperClipIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../common/Button/Button";
import {
  CustomButtonUploadFile,
  CustomButtonUploadFileHolder,
  UploadFileList,
} from "./style";
import { ImageResourceIcon } from "../../../common/Icons/ImageResourceIcon";
import { UrlResourceIcon } from "../../../common/Icons/UrlResourceIcon";
import { VideoResourceIcon } from "../../../common/Icons/VideoResourceIcon";
import { DocumentResourceIcon } from "../../../common/Icons/DocumentResourceIcon";
import { Config, FileInfo } from "../hooks/useConfigList";
import { Alert } from "../../../common/Alert";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "rgba(9, 119, 113, .8)",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Label(props: { id: string; title: string }) {
  return (
    <label
      htmlFor={props.id}
      className="block font-medium leading-6 text-gray-400 mb-2"
    >
      {props.title}
    </label>
  );
}

export function FileUploadDropzone(props: {
  filesInfo?: FileInfo[] | null;
  state: DropzoneState;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  className?: string;
  exceedFileLimit?: any;
  setExceedFileLimit?: any;
  dropzoneConfig?: any;
}) {
  const { getRootProps, getInputProps, fileRejections } = props.state;

  const getIcon = (type: any) => {
    if (
      type.includes("pdf") ||
      type.includes("doc") ||
      type.includes("docx") ||
      type.includes("csv")
    ) {
      return <DocumentResourceIcon />;
    } else if (
      type.includes("svg") ||
      type.includes("png") ||
      type.includes("jpg") ||
      type.includes("jpeg")
    ) {
      return <ImageResourceIcon />;
    } else if (type.includes("video")) {
      return <VideoResourceIcon />;
    }
    return <UrlResourceIcon />;
  };

  const files = props.files.map((file, i) => (
    <li key={i}>
      <div className="iconholder">{getIcon(file.type)}</div>
      <div className="info">
        <span className="title">{file.name}</span>
        <span className="des">{file.size} bytes</span>
      </div>
      <span
        className="close"
        onClick={() =>
          props.setFiles((files) => files.filter((f) => f !== file))
        }
      >
        <XCircleIcon />
      </span>
    </li>
  ));

  const filesInfo = props.filesInfo?.map((file, i) => (
    <li key={i}>
      <div className="iconholder">{getIcon(file.type)}</div>
      <div className="info">
        <span className="title">{file.name}</span>
        <span className="des">{file.size} bytes</span>
      </div>
      {/* <span
        className="close"
        onClick={() =>
          props.setFiles((files) => files.filter((f) => f !== file))
        }
      >
        <XCircleIcon />
      </span> */}
    </li>
  ));

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(props.state.isFocused ? focusedStyle : {}),
        ...(props.state.isDragAccept ? acceptStyle : {}),
        ...(props.state.isDragReject ? rejectStyle : {}),
      } as React.CSSProperties),
    [props.state.isFocused, props.state.isDragAccept, props.state.isDragReject]
  );

  return (
    <section>
      <aside></aside>
      <CustomButtonUploadFileHolder>
        <input {...getInputProps()} />
        <CustomButtonUploadFile type="button" {...getRootProps()}>
          <PaperClipIcon />
          Add Files
        </CustomButtonUploadFile>
        <p style={{ margin: "0 0 10px" }}>
          Supported files txt, htm, html, pdf, docx
        </p>
        {props.exceedFileLimit && (
          <Alert
            variant="filled"
            severity="error"
            className="break-all mt-2 mb-2"
            onClose={() => props.setExceedFileLimit(false)}
          >
            You can only upload up to {props.dropzoneConfig.maxFiles} files.
          </Alert>
        )}
        {fileRejections.length > 0 && (
          <>
            {fileRejections.map((reject, i) => (
              <Alert
                variant="filled"
                severity="error"
                className="break-all mt-2 mb-2"
                key={i}
              >
                {reject.file.name} - {reject.errors[0].message}
              </Alert>
            ))}
          </>
        )}
      </CustomButtonUploadFileHolder>
      <UploadFileList>{files}</UploadFileList>
      <UploadFileList>{filesInfo}</UploadFileList>
    </section>
  );
}
