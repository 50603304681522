import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../theme/theme";

export const StyledContainer = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  transition: "all ease-in-out 0.25s",
  flexShrink: 0,
}));

export const SidenavHolder = styled(Box)`
  position: fixed;
  top: 48px;
  height: calc(100% - 48px);
  z-index: 99;
  border-right: 1px solid ${brighthiveTheme.palette.custom.contrastText};

  width: inherit;
  overflow: auto;
  @media (max-width: 992px) {
    width: 180px;
  }
  .input {
    width: 100%;
    padding: 2px 10px;
    input {
      height: 33px;
      box-sizing: border-box;
      padding: 6px;
      font-size: 14px;
      box-shadow: none !important;
    }
  }
  .linkholder {
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      background: ${brighthiveTheme.palette.settings.lighter};
      a {
        button {
          color: ${brighthiveTheme.palette.common.black};
        }
      }
      .drop {
        button {
          opacity: 1;
        }
      }
    }
    &.active-chat {
      background: ${brighthiveTheme.palette.settings.active};
    }
    .action-button {
      padding: 0;
      min-width: 10px;
      margin-left: 5px;

      &.delete {
        svg {
          color: ${brighthiveTheme.palette.error.main};
        }
      }
      svg {
        font-size: 18px;
      }
      span {
        display: none;
      }
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      &:hover {
        text-decoration: none;
      }
      &.active-chat {
        button {
          color: ${brighthiveTheme.palette.common.black};
        }
        + .drop {
          button {
            opacity: 1;
          }
        }
      }
      button {
        padding: 10px;
        overflow: hidden;
        width: calc(100% - 30px);
        display: block;
        background: none;
        padding-right: 0;
        color: ${brighthiveTheme.palette.accent.contrastText};
        span {
          font-weight: normal;
        }

        &:hover {
          background: none;
          box-shadow: none;
        }
      }
      svg {
        font-size: 20px !important;
        @media (max-width: 992px) {
          display: none;
        }
      }

      .sidebutton-text {
        display: block;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
    }
  }

  @keyframes rollout {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: none;
    }
  }

  .roll-out {
    animation: rollout 0.4s;
  }
`;
export const CustomTitleTypography = styled(Typography)`
  font-size: 12px;
  margin: 25px 0 1px;
  padding: 2px 10px;
`;
export const ChatGroup = styled(Box)`
  &:first-child {
    h6 {
      margin-top: 10px;
    }
  }
`;
export const DropdownHolder = styled(Box)`
  position: absolute;
  right: 3px;
  top: 4px;

  button {
    background: transparent;
    color: ${brighthiveTheme.palette.accent.contrastText};
    box-shadow: none;
    min-width: 20px;
    padding: 5px;
    height: 30px;
    opacity: 0;

    &:hover {
      background: none;
      box-shadow: none;
      color: ${brighthiveTheme.palette.common.black};
    }

    svg {
      font-size: 16px !important;
    }
  }
`;

export const LeftHeaderContainer = styled(Box)(() => ({
  padding: "0 1.5rem 0 0",
  display: "flex",
  alignItems: "center",
}));

export const SidebarIconContainer = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  padding: "0.3rem .4rem",
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.outline,
  marginLeft: ".5rem",
  marginRight: "1rem",
}));

export const BrightbotIconContainer = styled(Box)(() => ({
  svg: {
    width: "40px",
    height: "40px",
    marginRight: ".5rem",
  },
}));

export const RightHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: " 100%",
  paddingRight: "5rem",
  justifyContent: "flex-end",
}));

export const ContentContainer = styled(Box)(({}) => ({
  display: "flex",
  flex: "1",
  height: "100%",
  marginTop: "-1rem",
}));

export const ChatContainer = styled(Box)(({}) => ({
  display: "flex",
  flex: "1",
  minHeight: "300px",
}));
