import React from "react";
import { alpha, createTheme } from "@mui/material";
import { typography } from "./typography";

declare module "@mui/material/styles" {
  interface Palette {
    gray: Palette["primary"];
    grayAccent: Palette["primary"];
    accent: Palette["primary"];
    link: Palette["primary"];
    maroon: React.CSSProperties["color"];
    settings: Palette["primary"];
    //Miscellaneous colors that didn't fit into theme
    surface: Palette["primary"];
    outline: Palette["primary"];
    outlineLight: Palette["primary"];
    scrim: Palette["primary"];
    disabledDarkBackground: Palette["primary"];
    disabledLightBackground: Palette["primary"];
    disabledDarkText: Palette["primary"];
    disabledLightTextOnDarkBackground: Palette["primary"];
    custom: Palette["primary"];
    customRolled: Palette["primary"];
    customScores: Palette["primary"];
    customViewer: Palette["primary"];
  }
  interface PaletteOptions {
    gray: PaletteOptions["primary"];
    grayAccent: PaletteOptions["primary"];
    accent: PaletteOptions["primary"];
    link: PaletteOptions["primary"];
    maroon: React.CSSProperties["color"];
    settings: PaletteOptions["primary"];
    //Miscellaneous colors that didn't fit into theme
    surface: React.CSSProperties["color"];
    outline: React.CSSProperties["color"];
    outlineLight: React.CSSProperties["color"];
    scrim: React.CSSProperties["color"];
    disabledDarkBackground: React.CSSProperties["color"];
    disabledLightBackground: React.CSSProperties["color"];
    disabledDarkText: React.CSSProperties["color"];
    disabledLightTextOnDarkBackground: React.CSSProperties["color"];
    custom: PaletteOptions["primary"];
    customRolled: PaletteOptions["primary"];
    customScores: PaletteOptions["primary"];
    customViewer: PaletteOptions["primary"];
  }
  interface PaletteColor {
    lighter?: string;
    darkest?: string;
    text?: string;
    heading?: string;
    border?: string;
    outline?: string;
    active?: string;
  }
  interface SimplePaletteColorOptions {
    darkest?: string;
    lighter?: string;
    text?: string;
    heading?: string;
    border?: string;
    outline?: string;
    // hover?: string;
    active?: string;
    // focus?: string;
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    gray: true;
    grayAccent: true;
    accent: true;
    link: true;
    settings: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    gray: true;
    grayAccent: true;
    accent: true;
    link: true;
    settings: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    gray: true;
    grayAccent: true;
    accent: true;
    link: true;
    settings: true;
  }
  interface ChipPropsSizeOverrides {
    large: true;
  }
}

export const brighthiveTheme = createTheme({
  spacing: (factor: number) => `${0.25 * factor}rem`,
  palette: {
    common: {
      black: "rgba(0, 0, 0, .9)",
      white: "rgb(255, 255, 255)",
    },
    action: {
      hoverOpacity: 0.04,
      activatedOpacity: 0.08,
      focusOpacity: 0.18,
      disabled: "rgba(0, 0, 0, .36)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      default: "rgb(250, 250, 250)",
    },
    primary: {
      lighter: "rgba(21, 185, 168, 0.04)",
      light: "rgb(205, 248, 240)",
      main: "rgb(73, 227, 211)",
      dark: "rgb(9, 119, 113)",
      darkest: "rgb(5, 82, 78)",
      outline: "rgba(21, 185, 168, 0.16)",
      active: "rgba(21, 185, 168, 0.12)",
      contrastText: "rgb(21, 185, 168)",
    },
    success: {
      lighter: "rgba(165, 218, 215, 1)",
      light: "rgba(102, 187, 176, 1)",
      main: "rgba(0, 158, 131, 1)",
      dark: "rgba(0, 158, 131, 1)",
      darkest: "rgba(0, 158, 131, 1)",
    },
    error: {
      lighter: "rgba(199, 20, 41, .07)",
      light: "rgb(255, 188, 209)",
      main: "rgb(199, 20, 41)",
      dark: "rgb(162, 16, 62)",
      darkest: "rgb(95, 33, 32)",
      outline: "rgba(199, 20, 41, 0.22)",
      active: "rgba(199, 20, 41, 0.14)",
    },
    warning: {
      lighter: "rgba(254, 190, 45, .1)",
      light: "rgb(255, 229, 169)",
      main: "rgb(254, 190, 45)",
      dark: "rgb(244, 176, 21)",
      darkest: "rgb(102, 60, 0)",
      outline: "rgba(234, 183, 65, 0.16)",
      active: "rgba(234, 183, 65, 0.24)",
    },
    accent: {
      light: "rgb(168, 229, 251)",
      main: "rgb(91, 203, 245)",
      dark: "rgb(15, 150, 200)",
      lighter: "rgba(91, 203, 245, .1)",
      outline: "rgba(91, 203, 245, 0.28)",
      active: "rgba(91, 203, 245, 0.18)",
      contrastText: "rgb(98, 110, 132)",
      darkest: "rgb(7, 71, 95)",
    },
    grayAccent: {
      light: "rgb(126, 138, 160)",
      main: "rgb(58, 67, 105)",
      dark: "rgb(31, 38, 65)",
      contrastText: "#FFFFFF",
      lighter: "#dfe1e7",
      darkest: "#545454",
    },
    settings: {
      light: alpha("rgba(0, 0, 0, .06)", 0.1),
      main: alpha("rgba(0, 0, 0, .06)", 0.1),
      dark: alpha("rgba(0, 0, 0, .06)", 0.1),
      contrastText: "rgb(58, 67, 105)",
      lighter: "rgb(58, 67, 105, 0.04)",
      darkest: "rgb(58, 67, 105, 0.16)",
      active: "rgb(58, 67, 105, 0.08)",
    },
    link: {
      light: "rgba(103, 111, 142, 1)",
      main: "rgb(36, 61, 162)",
      dark: "rgb(107, 26, 72)",
      contrastText: "#FFFFFF",
      lighter: "rgba(0, 189, 166, 0.16)",
    },
    gray: {
      light: "rgba(0, 0, 0, .06)",
      main: "rgba(0, 0, 0, .16)",
      dark: "rgba(0, 0, 0, .54)",
      darkest: "rgba(0, 0, 0, .72)",
      contrastText: "#e8e8e8",
      lighter: "rgba(255, 255, 255, 0.08)",
      text: "#5d5d5d",
      border: "rgba(0, 0, 0, .42)",
    },
    maroon: "#5F2120",
    surface: "rgb(255, 255, 255)",
    outline: "rgba(0, 0, 0, .12)",
    outlineLight: "rgba(33, 33, 33, 0.8)",
    scrim: "rgba(0, 0, 0, .4)",
    disabledLightBackground: "rgba(0, 0, 0, .04)",
    disabledDarkBackground: "rgba(0, 0, 0, .12)",
    disabledDarkText: "rgba(0, 0, 0, .36)",
    disabledLightTextOnDarkBackground: "rgba(255, 255, 255, .88)",
    custom: {
      main: "#2B2B2B",
      dark: "#121212",
      light: "#fafafa",
      contrastText: "#e0e0e0",
      lighter: "rgba(255, 255, 255, .2)",
      darkest: "#15B9A8",
      outline: "#ddd",
      border: "#EFEFF2",
    },
    customRolled: {
      main: "#E0CC79",
      outline: "rgba(73, 227, 211, 0.08)",
      light: "rgba(58, 67, 105, 0.06)",
      lighter: "rgba(33, 33, 33, 0.08)",
      dark: "rgb(214, 249, 243)",
      border: "rgba(0, 0, 0, 0.14)",
      contrastText: "rgba(0, 0, 0, 0.2)",
    },
    customScores: {
      main: "#9FE9DC",
      dark: "#FFAA9E",
      light: "#FFCBC4",
      contrastText: "#FFEDAF",
      text: "#D6F9F3",
    },
    customViewer: {
      main: "#FFCBC4",
      dark: "#0f544c",
      text: "#54575a",
    },
  },
  typography,
  components: {
    MuiCheckbox: {
      defaultProps: {
        sx: {
          pt: 1,
          pb: 1,
          pl: 2,
          pr: 2,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          px: 5,
          pb: 5,
        },
      },
    },
  },
});
