import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";

type Props = {
  code: string;
  language?: string;
};

type LineProps = {
  style: React.CSSProperties;
};

const CodeDisplayer = ({ code, language = "sql" }: Props) => {
  const lineProps: LineProps = {
    style: {
      wordBreak: "break-all",
      whiteSpace: "pre-wrap",
    },
  };
  return (
    <SyntaxHighlighter
      lineProps={lineProps}
      wrapLines={true}
      language={language}
      style={monokai}
    >
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeDisplayer;
