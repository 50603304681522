import { memo } from "react";
import type { Message, ToolCall } from "../types";
import { str } from "../utils/str";
import { cn } from "../utils/cn";
import {
  XCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import { PlusCircleViiIcon } from "../../../common/Icons/PlusCircleViiIcon";
import { StringEditor } from "./StringEditor";
import { JsonEditor } from "./JsonEditor";
import { useThreadAndAssistant } from "../hooks/useThreadAndAssistant";
import { v4 } from "uuid";
import {
  MessageHolder,
  MessageHolderRow,
  MessageWriter,
  MessageWriterName,
  MessageEditHolder,
  MessageEditFileHolder,
  AddCallBtn,
  AddArgumentBtn,
  AddNewBox,
} from "./style";
function ToolCallEditor(props: {
  value: ToolCall;
  onChange: (newValue: ToolCall) => void;
  onRemove: () => void;
  availableTools: string[];
}) {
  return (
    <MessageEditFileHolder>
      <div className="top-row">
        <span className="use-text">Use</span>
        <MessageEditFileHolder>
          {props.value.name !== undefined && (
            <select
              onChange={(e) =>
                props.onChange({ ...props.value, name: e.target.value })
              }
              value={props.value.name}
            >
              {props.availableTools.map((tool) => (
                <option key={tool} value={tool}>
                  {tool}
                </option>
              ))}
            </select>
          )}
        </MessageEditFileHolder>

        <DeleteIcon className="icon-trash" onClick={props.onRemove} />
      </div>
      <AddNewBox>
        <table>
          <tbody>
            {Object.entries(props.value.args).map(([key, value], i) => (
              <tr key={i}>
                <td className={cn(i === 0 ? "" : "border-top", "")}>
                  <input
                    value={key}
                    onChange={(e) => {
                      const newKey = e.target.value;
                      props.onChange({
                        ...props.value,
                        args: Object.fromEntries(
                          Object.entries(props.value.args).map(([k, v]) => [
                            k === key ? newKey : k,
                            v,
                          ])
                        ),
                      });
                    }}
                  />
                </td>
                <td className={cn(i === 0 ? "" : "border-top", "")}>
                  <div className="input-holder">
                    <StringEditor
                      value={str(value)?.toString()}
                      onChange={(newValue) => {
                        props.onChange({
                          ...props.value,
                          args: {
                            ...props.value.args,
                            [key]: newValue,
                          },
                        });
                      }}
                    />

                    <DeleteIcon
                      onClick={() => {
                        props.onChange({
                          ...props.value,
                          args: Object.fromEntries(
                            Object.entries(props.value.args).filter(
                              ([k]) => k !== key
                            )
                          ),
                        });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}

            <tr>
              <AddArgumentBtn
                className={cn("" in props.value.args && "disable")}
                onClick={
                  "" in props.value.args
                    ? undefined
                    : () => {
                        props.onChange({
                          ...props.value,
                          args: {
                            ...props.value.args,
                            "": "",
                          },
                        });
                      }
                }
              >
                <PlusCircleViiIcon />
                Add argument
              </AddArgumentBtn>
            </tr>
          </tbody>
        </table>
      </AddNewBox>
    </MessageEditFileHolder>
  );
}

export function ToolCallsEditor(props: {
  message: Message;
  onUpdate: (newValue: Message) => void;
}) {
  const { assistantConfig } = useThreadAndAssistant();
  const availableTools =
    assistantConfig?.config.configurable?.["type==agent/tools"]?.map(
      (t) => t.type
    ) ?? [];
  if (!props.message.tool_calls?.length && !availableTools.length) {
    return null;
  }
  return (
    <div>
      {props.message.tool_calls?.map((toolCall, i) => (
        <ToolCallEditor
          key={i}
          value={toolCall}
          onChange={(newValue) => {
            props.onUpdate({
              ...props.message,
              tool_calls: props.message.tool_calls?.map((tc, j) =>
                j === i ? newValue : tc
              ),
            });
          }}
          onRemove={() => {
            props.onUpdate({
              ...props.message,
              tool_calls: props.message.tool_calls?.filter((_, j) => j !== i),
            });
          }}
          availableTools={availableTools}
        />
      ))}
      <AddCallBtn
        className="pl-2 flex items-center gap-1 cursor-pointer opacity-50"
        onClick={() => {
          props.onUpdate({
            ...props.message,
            tool_calls: [
              ...(props.message.tool_calls ?? []),
              { id: v4(), name: availableTools[0], args: {} },
            ],
          });
        }}
      >
        <PlusCircleViiIcon className={cn("w-6 h-6")} />
        Add tool call
      </AddCallBtn>
    </div>
  );
}

export function MessageContentEditor(props: {
  message: Message;
  onUpdate: (newValue: Message) => void;
}) {
  if (typeof props.message.content === "string") {
    if (!props.message.content.trim()) {
      return null;
    }
    return (
      <StringEditor
        onChange={(newValue) =>
          props.onUpdate({
            ...props.message,
            content: newValue,
          })
        }
        className="stringeditor"
        value={props.message.content}
      />
    );
  }
  let content = props.message.content;
  if (Array.isArray(content)) {
    content = content.filter((it: any) =>
      typeof it === "object" && !!it && "type" in it
        ? it.type !== "tool_use"
        : true
    );
  }
  if (
    Array.isArray(content)
      ? content.length === 0
      : Object.keys(content).length === 0
  ) {
    return null;
  }

  return (
    <JsonEditor
      value={JSON.stringify(content, null, 2)}
      onChange={(newValue) => {
        try {
          props.onUpdate({
            ...props.message,
            content: JSON.parse(newValue),
          });
        } catch (e) {
          console.error(e);
        }
      }}
    />
  );
}

export const MessageEditor = memo(function (props: {
  message: Message;
  onUpdate: (newValue: Message) => void;
  abandonEdits: () => void;
}) {
  const isToolRes = ["function", "tool"].includes(props.message.type);
  return (
    <MessageHolder>
      <MessageHolderRow>
        <MessageWriter className={cn("", isToolRes && "mt-1")}>
          <MessageWriterName>{props.message.type}</MessageWriterName>

          <div className="close">
            <DeleteIcon onMouseUp={props.abandonEdits} />
          </div>
        </MessageWriter>
        <MessageEditHolder>
          <MessageContentEditor
            message={props.message}
            onUpdate={props.onUpdate}
          />
          {props.message.type === "ai" && props.message.tool_calls && (
            <ToolCallsEditor
              message={props.message}
              onUpdate={props.onUpdate}
            />
          )}
        </MessageEditHolder>
      </MessageHolderRow>
    </MessageHolder>
  );
});
