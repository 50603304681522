import { Box, Typography } from "@mui/material";
import React from "react";
import { MainContainer, StyledBrightbotSmileIcon } from "./style";

type Props = { title: string };

const EmptyTab = ({ title }: Props) => {
  return (
    <MainContainer>
      <Box>
        <StyledBrightbotSmileIcon />
        <Typography fontWeight="bold" variant="h5">
          It's empty in here.
        </Typography>
        <Typography fontWeight="regular" variant="subtitle2">
          Interact with Brightbot to get {title}
        </Typography>
      </Box>
    </MainContainer>
  );
};

export default EmptyTab;
