import { create } from "zustand";

interface SessionStore {
  threadId: string;
  assistantId: string;
  messages: any[];
  outputs: any[];
  reasoning: any[];
  plan: any[];
  fetchNewThread: (threadId: string, assistantId: string, values: any) => void;
  updateLogicState: (outputs: any[], plan: any[], reasoning: any[]) => void;
}

export const useSessionStore = create<SessionStore>((set) => ({
  threadId: "",
  assistantId: "",
  outputs: [],
  messages: [],
  reasoning: [],
  plan: [],
  fetchNewThread: (threadId: string, assistantId: string, values: any) => {
    set({
      threadId,
      assistantId,
      outputs: values?.outputs || [],
      reasoning: values?.reasoning || [],
      plan: values?.plan || [],
      messages: values?.messages || [],
    });
  },
  updateLogicState: (outputs: any[], plan: any[], reasoning: any[]) => {
    set({
      outputs: outputs || [],
      reasoning: reasoning || [],
      plan: plan || [],
    });
  },
}));
