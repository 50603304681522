import { Box } from "@mui/material";
import { IpynbRenderer } from "react-ipynb-renderer";
import "react-ipynb-renderer/dist/styles/default.css";
import useTab from "../../../../hooks/useTab";
import { ItemContentContainer, ItemWrapper } from "../../../../style";
import TitleWrapper from "../../../TitleWrapper";

type Props = {
  output: any;
  index: number;
  isClosed: boolean;
  handleCollapse: (index: number) => () => void;
};

const AnalysisBlock = ({ output, index, isClosed, handleCollapse }: Props) => {
  const { animationStyles } = useTab({ isClosed });

  return (
    <ItemWrapper>
      <TitleWrapper
        title="Analysis"
        handleCollapse={handleCollapse}
        index={index}
        animationStyles={animationStyles}
      />
      <ItemContentContainer sx={animationStyles.displayContainer}>
        {output?.content && (
          <Box>
            <IpynbRenderer ipynb={output.content} syntaxTheme="prism" />
          </Box>
        )}
      </ItemContentContainer>
    </ItemWrapper>
  );
};

export default AnalysisBlock;
