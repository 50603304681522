import { Box, styled } from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";

export const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column-reverse",
  flex: "1 1 0",
  overflowY: "auto",
});

export const SubMainContainer = styled(Box)({
  maxWidth: "890px",
  width: "100%",
  boxSizing: "border-box",
  margin: "0 auto",
  paddingBottom: "1rem",
  paddingX: "1.5rem",
});

export const LoadingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export const EditorContainer = styled(Box)({
  paddingInline: "1rem",
});

export const StyledStopIcon = styled(StopIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  height: "2rem !important",
  width: "2rem !important",
}));
