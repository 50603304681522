import {
  DescriptionSkeleton,
  HeaderSkeleton,
  InputContainer,
  InputSkeleton,
  MainContainer,
  PromptContainer,
  PromptsContainer,
} from "./style";

const ChatbotSkeleton = () => {
  return (
    <MainContainer>
      <PromptsContainer>
        {Array.from({ length: 3 }, (_, index) => (
          <PromptContainer key={index}>
            <HeaderSkeleton min={20} max={50} />
            <DescriptionSkeleton min={30} max={101} />
          </PromptContainer>
        ))}
      </PromptsContainer>
      <InputContainer>
        <InputSkeleton variant="rectangular" />
      </InputContainer>
    </MainContainer>
  );
};

export default ChatbotSkeleton;
