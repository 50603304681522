import { Box, styled } from "@mui/material";
import SkeletonComp from "../../SkeletonComp";

export const DateIntervalContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "1.563rem",
  paddingLeft: "0.625rem",
  paddingRight: "1.875rem",
});

export const HeaderSkeleton = styled(SkeletonComp)({
  fontSize: "0.75rem",
  marginBottom: "0.188rem",
});

export const TitleSkeleton = styled(SkeletonComp)({
  fontSize: "0.875rem",
  margin: "0.625rem 0",
});
