import {
  styled,
  Box,
  IconButton,
  Typography,
  Button,
  Tab,
  Grid,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { brighthiveTheme } from "../theme/theme";
import { Tabs } from "../common/Tabs/Tabs";

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 32px;

  .MuiTabs-indicator {
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover {
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;
export const CustomModalPolicy = styled(Box)`
  width: 50rem;
  box-sizing: border-box;
`;
export const FormHolder = styled(Box)`
  height: 30rem;
  overflow-y: auto;
`;
export const ButtonHolder = styled(Box)`
  display: flex;
  align-items: center;
`;
export const SidebarPolicy = styled(Grid)`
  width: 27rem;
  padding-top: 2rem;
  padding-left: 2rem;
  @media (max-width: 1154px) {
    width: 100%;
  }
`;
