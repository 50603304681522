import { Box } from "@mui/material";
import CodeDisplayer from "../../../../../../common/CodeDisplayer";
import useTab from "../../../../hooks/useTab";
import { ItemContentContainer, ItemWrapper } from "../../../../style";
import TitleWrapper from "../../../TitleWrapper";

type Props = {
  output: any;
  index: number;
  isClosed: boolean;
  handleCollapse: (index: number) => () => void;
};

const DBTBlock = ({ output, index, isClosed, handleCollapse }: Props) => {
  const { animationStyles } = useTab({ isClosed });

  return (
    <ItemWrapper>
      <TitleWrapper
        title="DBT"
        handleCollapse={handleCollapse}
        index={index}
        animationStyles={animationStyles}
      />
      <ItemContentContainer sx={animationStyles.displayContainer}>
        {output?.sql && (
          <Box>
            <CodeDisplayer code={output.sql} />
          </Box>
        )}
      </ItemContentContainer>
    </ItemWrapper>
  );
};

export default DBTBlock;
