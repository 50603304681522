import React, { useState } from "react";
import { Chat } from "../types";

enum Index {
  zero,
  one,
}

const useSearch = () => {
  const [searchChat, setSearchChat] = useState("");
  const [filterChat, setFilterChat] = useState<Chat[] | null>(null);

  const [searchConfig, setSearchConfig] = useState("");
  const [filterConfig, setFilterConfig] = useState<Chat[] | null>(null);

  const [searchWChat, setSearchWChat] = useState("");
  const [filterWChat, setFilterWChat] = useState<Chat[] | null>(null);

  const searchHandler = (
    chats: any,
    value: string,
    setFilter: Function,
    setSearch: Function
  ) => {
    setSearch(value);
    if (!chats) return;
    setFilter(
      chats?.filter((chat: any) =>
        chat?.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const filterSelect = (index: number, chat: any) => {
    switch (index) {
      case Index.zero:
        return searchWChat ? filterWChat : chat;
      case Index.one:
        return searchConfig ? filterConfig : chat;
    }
  };

  return {
    searchHandler,
    filterSelect,
    searchChat,
    filterChat,
    searchWChat,
    searchConfig,
    setSearchChat,
    setFilterChat,
    setSearchWChat,
    setFilterWChat,
    setSearchConfig,
    setFilterConfig,
  };
};

export default useSearch;
