import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { brighthiveTheme } from "../theme/theme";
import { CorporateFare } from "@mui/icons-material";
import { Form } from "formik";

export enum classes {
  label,
  editPasswordForm,
}

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const CustomTextField = ({ ...props }: TextFieldProps) => (
  <TextField
    inputProps={{
      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
    }}
    size="small"
    {...props}
  />
);

export const StyledTextField = styled(CustomTextField)``;

export const StyledTypography = styled(Typography)(({ theme, className }) => {
  if (className === classes[classes.label]) {
    return {
      color: theme.palette.gray.dark,
    };
  }
});

export const StyledCorporateFare = styled(CorporateFare)(({ theme }) => ({
  mr: 2,
  color: theme.palette.gray.dark,
  fontSize: "1rem",
  width: "1rem",
  height: "1rem",
}));

export const StyledForm = styled(Form)(({ className, theme }) => {
  if (className === classes[classes.editPasswordForm]) {
    return {
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: ".5rem",
      padding: "1.5rem 2rem",
      boxSizing: "border-box",
      width: "25rem",
      minWidth: "fit-content",
    };
  }
});

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 400,
}));

export const StyledConfirmationBox = styled(Box)(({ theme }) => ({
  position: "relative",
  boxShadow: `${theme.palette.success.light} 0px 2px 8px 0px;`,
  padding: "1.5rem 2rem",
  width: "fit-content",
  boxSizing: "border-box",
  margin: "1rem auto",
  borderRadius: "10px",
}));

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: "inherit",
  marginRight: ".5rem",
}));

export const StyledCloseButton = styled(Button)(({ theme }) => ({
  minWidth: "1px",
  position: "absolute",
  top: "0",
  right: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.gray.dark,

  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
  },
}));
export const TypographyHeding = styled(Typography)``;
