import { MarkedOptions, marked } from "marked";
import DOMPurify from "dompurify";
import { cn } from "../utils/cn";
import { MessageShowArea } from "./style";

const OPTIONS: MarkedOptions = {
  gfm: true,
  breaks: true,
};

export function StringViewer(props: {
  value: string;
  className?: string;
  markdown?: boolean;
}) {
  return props.markdown ? (
    <MessageShowArea
      className={cn("prose", props.className)}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(marked(props.value, OPTIONS) as any).trim(),
      }}
    />
  ) : (
    <MessageShowArea className={cn("prose", props.className)}>
      {props.value}
    </MessageShowArea>
  );
}
