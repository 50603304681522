import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  AreaChart,
  Area,
} from "recharts";
import { Button } from "../common/Button/Button";

import {
  AccountAdminHolder,
  TypographyHeding,
  AccountAdminFrame,
  AccountAdmincontent,
  AccountAdminsidebar,
  ContentBox,
  AccountAdminHead,
  AccountAdmintitle,
  BotIconText,
  TypographyHedingBig,
  TableUsage,
} from "./style";

import { Box, Typography, LinearProgress } from "@mui/material";
import { EmojiEmotions, FlashOn, Storage } from "@mui/icons-material";
import { ArrowDownIcon } from "../common/Icons/ArrowDownIcon";
import { ArrowUpIcon } from "../common/Icons/ArrowUpIcon";
import { useQuery } from "@apollo/client";
import { GetUsageDocument } from "../generated";
import { useParams } from "react-router-dom";
import {
  formatDateDDMonthShort,
  formatDateDDMonYear,
  formatDateMonthDD,
  formatDateYYYMMDD,
} from "../helpers";
import { BrightBotHomeIcon } from "../common/Icons/BrightBotHomeIcon";
import { BasicPage } from "../common/BasicPage/BasicPage";

const usageDataBar = [
  {
    label: "BrightBot (tokens)",
    value: 56,
    color: "#3b82f6",
    icon: <EmojiEmotions sx={{ color: "#3b82f6" }} />,
  },
  {
    label: "Compute (hours)",
    value: 17,
    color: "#f43f5e",
    icon: <FlashOn sx={{ color: "#f43f5e" }} />,
  },
  {
    label: "Storage (GB)",
    value: 21,
    color: "#f59e0b",
    icon: <Storage sx={{ color: "#f59e0b" }} />,
  },
];

const CustomDateTick = ({ x, y, payload }: any) => {
  const formattedDate = formatDateDDMonthShort(payload.value);

  return (
    <text
      x={x}
      y={y + 13}
      textAnchor="middle"
      fill="#666"
      style={{ paddingTop: "10px" }}
    >
      {formattedDate}
    </text>
  );
};

const UsageBreakdown = () => {
  return (
    <Box className="p-6">
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Usage Breakdown
      </Typography>
      {usageDataBar.map((item, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {item.icon}
            <Typography variant="body1" fontWeight="500">
              {item.label}
            </Typography>
          </Box>
          <Box sx={{ position: "relative", mt: 1 }}>
            <LinearProgress
              variant="determinate"
              value={item.value}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": { backgroundColor: item.color },
              }}
            />
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{ position: "absolute", right: 0, top: -3 }}
            >
              {item.value}%
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const endDate = new Date(); // Create a copy of the current date
const startDate = new Date(endDate); // Current date
startDate.setDate(endDate.getDate() - 30); // Subtract 30 days

export const AccountAdmin = () => {
  const { workspaceId } = useParams();
  const [showBreakdown, setBreakdown] = useState(false);

  const { data, loading } = useQuery(GetUsageDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      usageFilter: {
        endDate: formatDateYYYMMDD(endDate),
        startDate: formatDateYYYMMDD(startDate),
      },
    },
  });

  const formatYAxis = (tick: number) => {
    if (tick >= 1_000_000) return `${(tick / 1_000_000).toFixed(1)}M`;
    if (tick >= 1_000) return `${(tick / 1_000).toFixed(1)}K`;
    return tick;
  };

  const usageData = data?.workspace?.usage?.usageByTime
    ? Object.entries(data?.workspace?.usage?.usageByTime).map((date) => ({
        date: date[0],
        users: date[1],
      }))
    : [];

  const monthlyUsers = data?.workspace?.usage?.usageByUser;

  return (
    <>
      <BasicPage
        leftHeaderElements={<></>}
        content={
          <AccountAdminHolder>
            <AccountAdminFrame>
              <AccountAdmincontent>
                {/* Monthly Spend */}
                <ContentBox>
                  <AccountAdminHead>
                    <TypographyHeding variant="h6" className="small">
                      Monthly Usage
                    </TypographyHeding>
                    <AccountAdmintitle>
                      {`${formatDateMonthDD(startDate)} - ${formatDateMonthDD(
                        endDate
                      )}`}
                    </AccountAdmintitle>
                  </AccountAdminHead>
                  <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={usageData}>
                      <XAxis dataKey="date" tick={<CustomDateTick />} />
                      <YAxis tickFormatter={formatYAxis as any} />
                      <Tooltip
                        formatter={(value: number) => formatYAxis(value)}
                      />
                      <Bar dataKey="users" fill="#14b8a6" />
                    </BarChart>
                  </ResponsiveContainer>
                  {/* <Button
              onClick={() => setBreakdown(!showBreakdown)}
              className="mt-4"
            >
              View Breakdown{" "}
              {showBreakdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Button>
            {showBreakdown && <UsageBreakdown />} */}
                </ContentBox>
              </AccountAdmincontent>
              <AccountAdminsidebar>
                {/* Monthly Usage Breakdown */}
                <ContentBox>
                  <AccountAdminHead className="add">
                    <TypographyHeding variant="h6" className="small">
                      Monthly Usage Breakdown
                    </TypographyHeding>
                    <AccountAdmintitle className="add">
                      {`${formatDateMonthDD(startDate)} - ${formatDateMonthDD(
                        endDate
                      )}`}
                    </AccountAdmintitle>
                  </AccountAdminHead>
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={usageData}>
                      <XAxis dataKey="date" tick={<CustomDateTick />} />
                      <YAxis tickFormatter={formatYAxis as any} />
                      <Tooltip
                        formatter={(value: number) => formatYAxis(value)}
                      />
                      <Area
                        type="monotone"
                        dataKey="users"
                        stroke="#3b82f6"
                        fill="#3b82f6"
                        fillOpacity={0.3}
                      />
                      {/* <Area
                  type="monotone"
                  dataKey="compute"
                  stroke="#f43f5e"
                  fill="#f43f5e"
                  fillOpacity={0.3}
                />
                <Area
                  type="monotone"
                  dataKey="storage"
                  stroke="#f59e0b"
                  fill="#f59e0b"
                  fillOpacity={0.3}
                /> */}
                    </AreaChart>
                  </ResponsiveContainer>
                  <BotIconText>
                    <BrightBotHomeIcon /> BrightBot (Tokens)
                  </BotIconText>
                </ContentBox>
                {/* Monthly Users */}
                <ContentBox>
                  <TypographyHedingBig variant="h6">
                    Monthly Usage
                  </TypographyHedingBig>
                  <AccountAdmintitle className="more">
                    <b>{monthlyUsers?.length || 0}</b> Active Users
                  </AccountAdmintitle>
                  <TableUsage>
                    <table>
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Credits Used</th>
                        </tr>
                      </thead>
                      {monthlyUsers?.map((user: any) => (
                        <tr>
                          <td key={user.userId}>
                            <span>{user.name}</span>
                          </td>
                          <td>
                            {/* {user.usage.toLocaleString()} credits - */}
                            {formatYAxis(user.usage)}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </TableUsage>
                  {/* <div>
              <Button className="m-auto block">View Old Records</Button>
            </div> */}
                </ContentBox>
              </AccountAdminsidebar>
            </AccountAdminFrame>
          </AccountAdminHolder>
        }
      />
    </>
  );
};
