import { useState } from "react";
import { FormikValues } from "formik";
import { Box, ButtonBase, Grid, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";

import { Typography } from "../../common/Typography/Typography";
import { FilterByTagButton } from "../../common/ActionBarProjects/FilterByTagButton";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { GovernancePolicyItem } from "../GovernancePolicyItem";
import { Chip } from "../../common/Chip/Chip";
import { PolicyOperationConfirmModal } from "../PolicyOperationConfirmModal";

import { useQuery } from "@apollo/client";
import {
  GetCustomPoliciesDocument,
  GetMonitoredPoliciesDocument,
  UpdateCustomPolicyInput,
} from "../../generated";
import { Policy } from "../types";
import { AddCustomPolicyButton } from "../GovernanceCustomPolicies/AddCustomPolicyButton";
import { GovernanceCustomPolices } from "../GovernanceCustomPolicies/GovernanceCustomPolicies";
import CustomPolicyCard from "../GovernanceCustomPolicies/CustomPolicyCard";
import MonitoredPolicyCard from "../GovernanceMonitoredPolicies/MonitoredPolicyCard";
import { SidebarPolicy } from "../style";

export const GovernancePolicies = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [operation, setOperation] = useState<"delete" | "update">("delete");
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<Policy | null>();
  const { data: monitoredPolicies } = useQuery(GetMonitoredPoliciesDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  }) as unknown as {
    data: {
      isEditable: boolean;
      categories: Array<[string, Array<Policy>]>;
    };
  };

  const { data: customPolicies, refetch } = useQuery(
    GetCustomPoliciesDocument,
    {
      variables: { input: { workspaceId: workspaceId || "" } },
    }
  ) as unknown as {
    refetch: Function;
    data: {
      isEditable: boolean;
      categories: Array<[string, Array<Policy>]>;
    };
  };

  const flattenCustomPolicy = customPolicies?.categories
    .map((polcies) => polcies[1])
    .flat();

  const isCustomPolicy = (item: Policy) => {
    if (flattenCustomPolicy?.find((policy: Policy) => policy.id === item.id))
      return true;

    return false;
  };

  const isMonitoredEditable = monitoredPolicies?.isEditable;
  const isCustomEditable = customPolicies?.isEditable;
  let policyData = monitoredPolicies?.categories
    .concat(customPolicies?.categories || [])
    .map(([category, policies]) => ({
      category,
      policies,
    }));

  const tags = ["HIPPA", "FERRA", "PROJECTS", "ONE", "TWO"];
  const [tagValues, setTagValues] = useState<FormikValues>({
    HIPPA: false,
    FERRA: false,
    PROJECTS: false,
    ONE: false,
    TWO: false,
  });
  const [selectedPolicyItemIndex, setSelectedPolicyItemIndex] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [keyword, setKeyword] = useState("");

  const hasKeyword = (policy: any) => {
    return (
      policy.title.toLowerCase().includes(keyword.toLowerCase()) ||
      policy.description.toLowerCase().includes(keyword.toLowerCase())
    );
  };

  const selectedTagList = () => {
    return tags.filter((tag: string) => tagValues[tag]);
  };

  const hasSelectedTag = (policy: any) => {
    if (selectedTagList().length === 0) return true;
    const result = (policy.tags || []).filter((tag: string) => tagValues[tag]);

    return result.length > 0;
  };

  const handleCategoryItemClick = (index: number) => {
    if (index === selectedPolicyItemIndex) {
      setSelectedPolicyItemIndex(-1);
    } else {
      setSelectedPolicyItemIndex(index);
    }
  };

  const handleOpenNewTab = (item: Policy) => {
    localStorage.setItem("DETAIL", JSON.stringify(item));

    if (isCustomPolicy(item)) {
      window.open(`/workspace/${workspaceId}/governance/detail-id?tab=custom`);
      return;
    } else {
      window.open(
        `/workspace/${workspaceId}/governance/detail-id?tab=monitored&id=${item.id}`
      );
    }
  };

  const handleDelete = (item: Policy) => {
    setOperation("delete");
    setSelected(item);
    setShowConfirm(true);
  };

  const handleEdit = (item: Policy) => {
    setSelected(item);
    setShowEdit(true);
  };

  const { palette } = useTheme();

  if (policyData)
    return (
      <Grid container spacing={8}>
        <Grid item xs>
          <Box display="flex" alignItems="center">
            <SearchBar
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              sx={{
                backgroundColor: palette.common.white,
                mr: "auto",
                width: "20rem",
              }}
              placeholder="Find a policy"
            />
            {/* <FilterByTagButton
              tags={tags}
              text="Tag"
              values={tagValues}
              onSubmit={setTagValues}
            /> */}

            {isCustomEditable && <AddCustomPolicyButton />}
            {/* <AddMonitoredPolicyButton /> */}
          </Box>
          <Box marginTop="2rem">
            {policyData.map((category, index) => {
              return (
                <Box
                  marginBottom={keyword.length > 0 ? "0rem" : "2rem"}
                  key={`category-${index}`}
                >
                  {keyword.length === 0 && selectedTagList().length === 0 && (
                    <Box display="flex" justifyContent={"space-between"}>
                      <ButtonBase
                        onClick={() => handleCategoryItemClick(index)}
                      >
                        {selectedPolicyItemIndex === index ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                        <Typography variant="h6">
                          {category.category}
                        </Typography>
                      </ButtonBase>
                      <Chip
                        label={`${category?.policies?.length} policies `}
                        size="medium"
                        color={`${
                          category?.policies?.length === 0 ? "gray" : "primary"
                        }`}
                      />
                    </Box>
                  )}
                  {((selectedPolicyItemIndex === index &&
                    keyword.length === 0) ||
                    keyword.length !== 0 ||
                    selectedTagList().length !== 0) &&
                    (category?.policies).map((policy, index) => (
                      <Box marginTop="1rem" key={`policy-${index}`}>
                        {hasKeyword(policy) && hasSelectedTag(policy) && (
                          <>
                            {isCustomPolicy(policy) ? (
                              <GovernancePolicyItem
                                addedBy={(policy as any).addedBy}
                                handleOpenNewTab={() =>
                                  handleOpenNewTab(policy)
                                }
                                tagValues={tagValues}
                                keyword={keyword}
                                isEditable={isCustomEditable}
                                handleEdit={() => handleEdit(policy)}
                                handleDelete={() => handleDelete(policy)}
                                {...policy}
                              />
                            ) : (
                              <GovernancePolicyItem
                                addedBy={(policy as any).addedBy}
                                handleOpenNewTab={() =>
                                  handleOpenNewTab(policy)
                                }
                                tagValues={tagValues}
                                keyword={keyword}
                                isEditable={isMonitoredEditable}
                                {...policy}
                              />
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                </Box>
              );
            })}
          </Box>
        </Grid>
        <SidebarPolicy>
          <MonitoredPolicyCard />
          <CustomPolicyCard />
        </SidebarPolicy>

        <GovernanceCustomPolices
          refetch={refetch}
          selected={selected}
          operation={operation}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          setOperation={setOperation}
        />
      </Grid>
    );
  return null;
};
