import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({}) => ({
  position: "relative",
  overflow: "auto",
}));

export const StyledBackgroundContainer = styled(Box)(({}) => ({
  position: "relative",
  zIndex: "1",
  height: "100%",
  width: "100%",
}));

export const StyledHeroContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  paddingInline: "2rem",
  minHeight: "calc(100vh - 48px)",
  padding: "20px 15px 0",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "60%",
    background: `linear-gradient(to top, #CDF8F0, ${theme.palette.common.white})`,
    pointerEvents:
      "none" /* Ensures the shadow does not interfere with interactions */,
  },
}));

export const StyledHeroContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: "65rem",
  marginInline: "auto",
}));
