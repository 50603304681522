import { useCallback, useEffect, useReducer } from "react";
import orderBy from "lodash/orderBy";
import { getAssistants } from "../api/assistants";
import bBaseApi from "../api/bBaseApi";
import { useNavigate, useParams } from "react-router-dom";

export interface FileInfo {
  name: string;
  size: number;
  type: string;
}

export interface Config {
  assistant_id: string;
  name: string;
  updated_at: string;
  config: {
    configurable?: {
      type?: string;
      "type==agent/tools"?: {
        type: string;
        name: string;
        description: string;
      }[];
      [key: string]: unknown;
    };
    filesInfo?: FileInfo[];
  };
  public: boolean;
  mine?: boolean;
}

export interface ConfigListProps {
  configs: Config[] | null;
  saveConfig: (
    key: string,
    config: Config["config"],
    files: File[],
    isPublic: boolean,
    assistantId?: string
  ) => Promise<string>;
  deleteConfig: (assistantId: string) => Promise<void>;
}

function configsReducer(
  state: Config[] | null,
  action: Config | Config[]
): Config[] | null {
  state = state ?? [];
  if (!Array.isArray(action)) {
    const newConfig = action;
    action = [
      ...state.filter((c) => c.assistant_id !== newConfig.assistant_id),
      newConfig,
    ];
  }
  return orderBy(action, "updated_at", "desc");
}

const baseUrl = process.env.REACT_APP_BB_ASSISTANT_URL;

export function useConfigList(): ConfigListProps {
  const { workspaceId, assistantId } = useParams();
  const navigate = useNavigate();
  const [configs, setConfigs] = useReducer(configsReducer, null);

  useEffect(() => {
    async function fetchConfigs() {
      const assistants = await getAssistants();
      setConfigs(
        assistants ? assistants.map((c) => ({ ...c, mine: true })) : []
      );
    }

    fetchConfigs();
  }, []);

  const saveConfig = useCallback(
    async (
      name: string,
      config: Config["config"],
      files: File[],
      isPublic: boolean,
      assistantId?: string
    ): Promise<string> => {
      let filesInfo: any = [];
      if (files.length) {
        filesInfo = files.map((item) => ({
          name: item.name,
          size: item.size,
          type: item.type,
        }));
      }
      const confResponse = await (assistantId ? bBaseApi.put : bBaseApi.post)(
        assistantId ? `/assistants/${assistantId}` : "/assistants",
        JSON.stringify({
          name,
          config: { ...config, filesInfo: filesInfo },
          public: isPublic,
        })
      );
      const savedConfig = (await confResponse.data) as Config;
      if (files.length) {
        const assistant_id = savedConfig.assistant_id;
        const formData = files.reduce((formData, file) => {
          formData.append("files", file);
          return formData;
        }, new FormData());
        formData.append(
          "config",
          JSON.stringify({ configurable: { assistant_id } })
        );

        await bBaseApi.post(`/ingest`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      setConfigs({ ...savedConfig, mine: true });
      return savedConfig.assistant_id;
    },
    []
  );

  const deleteConfig = useCallback(
    async (assistant_id: string): Promise<void> => {
      await bBaseApi.delete(`/assistants/${assistant_id}`);
      setConfigs(
        (configs || []).filter((c) => c.assistant_id !== assistant_id)
      );

      if (assistant_id === assistantId) {
        navigate(`/workspace/${workspaceId}/brightbot-studio`);
      }
    },
    [assistantId, configs, navigate, workspaceId]
  );

  return {
    configs,
    saveConfig,
    deleteConfig,
  };
}
