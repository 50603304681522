import { size } from "lodash";
import React, { PropsWithChildren } from "react";
import EmptyTab from "../EmptyTab";

type Props = PropsWithChildren<{ list: any; title: string }>;

const TabWrapper = ({ list, children, title }: Props) => {
  return <>{size(list) > 0 ? children : <EmptyTab title={title} />}</>;
};

export default TabWrapper;
