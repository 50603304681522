import { styled, Box } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const TopNavRight = styled(Box)`
  position: fixed;
  transition: all ease-in-out 0.25s;
  right: 0;
  top: 0;
  background: ${brighthiveTheme.palette.common.white};
  height: 48px;
  justify-content: center;
  zindex: 0;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px -1px 0px ${brighthiveTheme.palette.action.disabledBackground};
  @media (min-width: 768px) {
    z-index: 100;
  }
`;
export const Page = styled(Box)`
  &.main-sidenav-collapsed {
    .ChatBotPage {
      .typing {
        left: 268px;
        @media (max-width: 992px) {
          left: 228px;
        }
      }
      .sidebar-closed {
        .typing {
          left: 48px;
        }
      }
      .page-footer {
        left: 268px;
        @media (max-width: 992px) {
          left: 228px;
        }
      }
      .sidebar-closed {
        .page-footer {
          left: 48px;
        }
      }
    }
  }
`;
