import { Box, styled } from "@mui/material";
import "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";

export const Container = styled(Box)(({}) => ({
  overflow: "hidden",
  maxWidth: "43.25rem",
  transition: "flex-basis 0.5s ease-in-out",
}));

export const SidebarContainer = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  padding: "1rem",
  paddingTop: "1rem",
  boxSizing: "border-box",
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.outline}`,
  backgroundColor: theme.palette.custom.light,
  display: "flex",
  flexDirection: "column",
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  display: "flex",
  justifyContent: "flex-end",
  padding: "0.75rem 1rem",
  button: {
    padding: "5px",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grayAccent.light,
    minWidth: "inherit",
    margin: "0 5px",
    position: "relative",
    zIndex: "10",

    svg: {
      width: "16px",
      height: "16px",

      "&.pause": {
        width: "10px",
        height: "12px",
      },
    },

    "&:hover, &.active, &:active": {
      color: theme.palette.primary.main,
    },

    "&:disabled": {
      opacity: "0.5",
    },
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1",
  backgroundColor: theme.palette.common.white,
}));

export const StateParentContainer = styled(Box)(({}) => ({
  overflow: "auto",
  flex: "1 1 0",
  display: "flex",
}));

export const StateContainer = styled(Box)(({}) => ({
  width: "100%",
  marginTop: "1rem",
  boxSizing: "border-box",
  paddingInline: "1.5rem",
  paddingTop: "1.5rem",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column-reverse",
}));

// if height is added here the scroll will be removed
export const ItemsContainer = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  paddingBottom: "1rem",
}));

export const ItemWrapper = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  padding: ".5rem",
  boxSizing: "border-box",
  borderRadius: ".7rem",
  width: "100%",
  maxHeight: "50vh",
  backgroundColor: "white",
}));

export const ItemContentContainer = styled(Box)(({}) => ({
  minHeight: "3rem",
  transition: "all .5s, overflow 0s",
  overflowY: "auto",
  padding: ".5rem",
  maxHeight: "50vh",
  boxSizing: "border-box",
}));

export const FooterContainer = styled(Box)(({}) => ({
  overflow: "hidden",
  padding: "0.5rem 1.75rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const FooterBtnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  height: "2.25rem",
  width: "2.25rem",
  border: `1px solid ${theme.palette.primary.darkest}`,
  borderRadius: "3px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledAGGrid = styled(AgGridReact)(({ theme }) => ({
  ".ag-root": {
    border: `.0625rem ${theme.palette.outline} solid`,
  },

  ".ag-scrolls": {
    height: "auto !important",
  },

  "& .ag-root-wrapper": {
    border: "1px solid #ccc", // Border around the table
    borderRadius: "4px", // Optional: Rounded corners
  },
  "& .ag-header": {
    borderBottom: "1px solid #ccc", // Border below the header
    backgroundColor: "#f9f9f9", // Light background for the header (optional)
  },
  "& .ag-header-cell": {
    padding: "8px 12px",
    borderRight: "1px solid #ccc", // Vertical cell borders
    fontWeight: 500, // Slightly bold header text
    display: "flex",
    alignItems: "center", // Vertically center header text
  },
  "& .ag-cell": {},
}));
