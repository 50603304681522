export enum userType {
  human = "human",
  ai = "ai",
}

export enum streamErrorTypes {
  UserInterrupt = "UserInterrupt",
}

export enum streamEventType {
  metadata = "metadata",
  error = "error",
  events = "events",
  chatModelStream = "on_chat_model_stream",
  end = "end",
}
