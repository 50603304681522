import { useState } from "react";
import { Card, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import { PageDivider, TabButton, TabsWrapper } from "../../AssetDetail/style";
import { MyProfilePage } from "../../ContentPages/MyProfilePage";
import { AccountAdmin } from "../../AccountAdmin";
import { Typography } from "../Typography/Typography";
import { ProfilePage } from "../../AccountAdmin/style";

export const ProfileTabs = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const isEditMode = Boolean(sp.get("edit"));
  const tabParam = sp.get("tab");

  const [tab, setTab] = useState(tabParam === "admin" ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  return (
    <ProfilePage>
      <Card className="page-title" sx={{ pt: 6, pl: 6, pr: 6, pb: 0 }}>
        <Typography variant="h5">My Profile</Typography> <PageDivider />
      </Card>

      <TabsWrapper
        value={tab}
        onChange={handleChange}
        sx={{ mb: 4 }}
        aria-label="asset detail tab"
      >
        <TabButton label="My Profile" {...a11yProps(0)} />
        <TabButton label="Usage" {...a11yProps(1)} />
      </TabsWrapper>

      {tab === 0 && <MyProfilePage />}
      {tab === 1 && <AccountAdmin />}
    </ProfilePage>
  );
};
