import { useCallback, useEffect, useState } from "react";
import UppyModal from "../UppyModal/UppyModal";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  GetDataAssetsDocument,
  UpdateDataAssetFileDocument,
  UploaderWriteMode,
} from "../../generated";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AppendCheckIcon } from "../Icons/AppendCheckIcon";
import { AppendReplaceIcon } from "../Icons/AppendReplaceIcon";
import { Label } from "../Label/Label";

export interface AddDataAssetButtonProps {
  onClick: (type: AddDataAssetButtonType) => void;
}

export enum AddDataAssetButtonType {
  Upload = "Upload",
  ExtractLoad = "Extract and load",
  APIconnect = "API connect",
  BulkTransfer = "Bulk Transfer",
}

export const UpdateDataAsset = ({
  uploadOpen,
  setUploadOpen,
}: {
  uploadOpen: boolean;
  setUploadOpen: Function;
}) => {
  const [dataAssetId, setDataAssetId] = useState<string>("");
  const [reRender, setReRender] = useState(true);
  const [writeMode, setWriteMode] = useState(UploaderWriteMode.Append);
  const { workspaceId } = useParams();
  const { data } = useQuery(GetDataAssetsDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });
  const [updateDataAsset] = useMutation(UpdateDataAssetFileDocument);
  const client = useApolloClient();

  const uploadParameters = useCallback(
    async (file: any) => {
      const res = await updateDataAsset({
        variables: {
          input: {
            dataAssetId: dataAssetId || "",
            workspaceId: workspaceId || "",
            name: file.name,
            writeMode: writeMode,
          },
        },
      });
      return {
        url: res.data?.updateDataAssetFile?.presignedUrl || "",
        headers: {
          "content-type": "text/csv",
        },
        method: "PUT",
      };
    },
    [dataAssetId, workspaceId, writeMode]
  );

  useEffect(() => {
    if (reRender === false) {
      setReRender(true);
    }
  }, [reRender]);
  const theme = useTheme();

  return (
    <>
      {dataAssetId && reRender && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "26px",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.common.black,
                fontSize: "12px",
                fontWeight: "400",

                "@media (min-width: 576px)": {
                  fontSize: "14px",
                },

                ".MuiSvgIcon-root": {
                  width: "10px",
                  height: "14px",
                  marginRight: "6px",
                  verticalAlign: "-0.1em",
                },
              }}
            >
              <AppendCheckIcon />
              {writeMode}
            </Typography>
            <Checkbox
              checked={writeMode === UploaderWriteMode.Append || false}
              onChange={() => {
                setReRender(false);
                setWriteMode((prev) =>
                  prev === UploaderWriteMode.Append
                    ? UploaderWriteMode.Overwrite
                    : UploaderWriteMode.Append
                );
              }}
              sx={{
                width: "36px",
                height: "14px",
                borderRadius: "99px",
                padding: "0",
                position: "relative",

                "&.Mui-checked .MuiTouchRipple-root": {
                  left: "16px",
                },

                "&:before": {
                  position: "absolute",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  borderRadius: "inherit",
                  content: "''",
                  backgroundColor: theme.palette.primary.main,
                  opacity: "0.32",
                },

                ".MuiSvgIcon-root": {
                  display: "none",
                },

                ".MuiTouchRipple-root": {
                  width: "20px",
                  height: "20px",
                  borderRadius: "100%",
                  backgroundColor: theme.palette.primary.main,
                  right: "auto",
                  bottom: "auto",
                  top: "50%",
                  transform: "translateY(-50%)",
                  boxShadow: `0 2px 1px -1px ${theme.palette.action.disabledBackground}`,
                  transition: "left 0.3s ease",
                },
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.grayAccent.light,
                fontSize: "12px",
                fontWeight: "400",

                "@media (min-width: 576px)": {
                  fontSize: "14px",
                },

                ".MuiSvgIcon-root": {
                  width: "13px",
                  height: "14px",
                  marginRight: "6px",
                  verticalAlign: "-0.1em",
                },
              }}
            >
              <AppendReplaceIcon />
              Replace
            </Typography>
          </Box>

          <Box
            sx={{
              position: "relative",
              ".uploader-tooltip": {
                left: "75px",
                top: "0px",
              },
            }}
          >
            <Label
              value="Please note that each file that you upload will be appended to your original data asset"
              className="uploader-tooltip"
              sx={{
                fontSize: "0",
                lineHeight: "0",
                color: theme.palette.common.black,
                m: 0,
                svg: {
                  m: 0,
                },
              }}
              info
            />
            <UppyModal
              open={uploadOpen}
              onClose={() => {
                client.refetchQueries({ include: [GetDataAssetsDocument] });
                setUploadOpen(false);
              }}
              getUploadParameters={uploadParameters}
            />
          </Box>
        </>
      )}
      <Autocomplete
        className="chat-auto-complete"
        id="free-solo-demo"
        freeSolo
        options={data?.workspace?.dataAssets || []}
        getOptionLabel={(option: any) => option?.name}
        renderInput={(params) => (
          <TextField {...params} placeholder="Please Select Data Asset" />
        )}
        onChange={(_, value: any) => {
          setReRender(false);
          setDataAssetId(value?.id);
        }}
      />
    </>
  );
};
