import Add from "@mui/icons-material/Add";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  useTheme,
} from "@mui/material";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "../Button/Button";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TurndownService from "turndown";
import ShowdownService from "showdown";

import EmojiPicker from "emoji-picker-react";

import Popover from "@mui/material/Popover";
import { useData } from "../../hooks/dataContext";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { palette } from "@mui/system";
import { ButtonPrimary, BtnHolder } from "./style";

const turndownService = new TurndownService();
const showdownService = new ShowdownService.Converter();

interface RichTextEditorProps {
  onSubmit?: any;
}

const RichTextEditor = ({ onSubmit }: RichTextEditorProps) => {
  const [value, setValue] = useState("");
  const { state } = useData();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function suggestPeople(searchTerm: string) {
    const allPeople = state.members?.map((item: any) => ({
      id: item.id,
      value: `${item.firstName} ${item.lastName}`,
    }));
    return allPeople.filter(
      (person: any) =>
        person.id !== state.user.id &&
        person.value.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value) {
      onSubmit(turndownService.turndown(value));
      setValue("");
    }
  };

  const { palette } = useTheme();

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(v) => {
          setValue(v);
        }}
        modules={{
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            source: useCallback(
              async (
                searchTerm: string,
                renderItem: (items: any) => void,
                mentionChar: string
              ) => {
                const matchedPeople = await suggestPeople(searchTerm);
                renderItem(matchedPeople);
              },
              []
            ),
          },
        }}
      />
      <BtnHolder>
        <Box display="flex" gap={2} alignItems="center">
          {/* <IconButton
            size="small"
            sx={{
              background: palette.settings.lighter,
              height: "24px",
              width: "24px",
            }}
          >
            <Add fontSize="small" />
          </IconButton>
          <Divider orientation="vertical" variant="middle" flexItem /> */}
          {/* <IconButton size="small" sx={{ padding: 0 }} onClick={handleClick}>
            <InsertEmoticonIcon fontSize="small" />
          </IconButton> */}
          {/* <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => {
              setOpenTag(true);
            }}
          >
            <AlternateEmailIcon fontSize="small" />
          </IconButton> */}
          {/* <IconButton
            size="small"
            sx={{ padding: 0, transform: "rotate(45deg)" }}
          >
            <AttachFileIcon fontSize="small" />
          </IconButton> */}
        </Box>
        <Box>
          <ButtonPrimary
            type="submit"
            size="small"
            variant="contained"
            color="primary"
          >
            Post
          </ButtonPrimary>
        </Box>
      </BtnHolder>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <EmojiPicker
          onEmojiClick={(v) => {
            setValue(value + v.emoji);
            handleClose();
          }}
        />
      </Popover>
    </Box>
  );
};

export default RichTextEditor;
