import {
  PaperAirplaneIcon,
  ChatBubbleLeftIcon,
  XCircleIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  DocumentIcon,
} from "@heroicons/react/20/solid";
import { AttachmentIcon } from "../../../common/Icons/AttachmentIcon";
import { cn } from "../utils/cn";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MessageWithFiles } from "../utils/formTypes";
import { DROPZONE_CONFIG, TYPE_NAME } from "../constants";
import { Config } from "../hooks/useConfigList";
import { Chat } from "../types";
import {
  FilesListItem,
  FilesListItemClose,
  FilesListItemHolder,
  TypingAreaHolder,
  UploadFileLists,
  TypingForm,
  InputHolder,
  ChatIconHolder,
  FileUploadLink,
  ButtonChatSubmit,
  TypingFormInput,
} from "./style";
import { Alert } from "../../../common/Alert";
import { fileId } from "./Config";

function getFileTypeIcon(fileType: string) {
  switch (fileType) {
    case "text/plain":
    case "text/csv":
    case "text/html":
      return <DocumentTextIcon />;
    case "application/pdf":
      return <DocumentIcon />;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      return <DocumentIcon />;
    default:
      return <DocumentIcon />;
  }
}

function FileIcon(props: { fileType: string }) {
  return <div className="file-holder">{getFileTypeIcon(props.fileType)}</div>;
}

function convertBytesToReadableSize(bytes: number) {
  const units = ["bytes", "KB", "MB", "GB", "TB"];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(1)} ${units[i]}`;
}

export default function TypingBox(props: {
  onSubmit: (data: MessageWithFiles) => void;
  onInterrupt?: () => void;
  inflight?: boolean;
  currentConfig: Config;
  currentChat: Chat | null;
}) {
  const [inflight, setInflight] = useState(false);
  const isInflight = props.inflight || inflight;
  const [files, setFiles] = useState<File[]>([]);
  const [isDocumentRetrievalActive, setIsDocumentRetrievalActive] =
    useState(false);

  const [rejectedFiles, setRejectedFiles] = useState(false);
  const [exceedFileLimit, setExceedFileLimit] = useState(false);

  const { currentConfig, currentChat } = props;

  useEffect(() => {
    let configurable = null;
    if (currentConfig) {
      configurable = currentConfig.config?.configurable;
    }
    const agent_type = configurable?.["type"] as TYPE_NAME | null;
    if (agent_type === null || agent_type === "chatbot") {
      setIsDocumentRetrievalActive(false);
      return;
    }
    if (agent_type === "chat_retrieval") {
      setIsDocumentRetrievalActive(true);
      return;
    }
    const tools =
      (configurable?.["type==agent/tools"] as { name: string }[]) ?? [];
    setIsDocumentRetrievalActive(tools.some((t) => t.name === "Retrieval"));
  }, [currentConfig, currentChat]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => {
      const newFiles = acceptedFiles.filter(
        (acceptedFile) =>
          !prevFiles.some(
            (prevFile) =>
              prevFile.name === acceptedFile.name &&
              prevFile.size === acceptedFile.size
          )
      );
      return [...prevFiles, ...newFiles];
    });
  }, []);

  const dropzone = useDropzone(DROPZONE_CONFIG);

  const FilesToShow = files.map((file) => {
    const readableSize = convertBytesToReadableSize(file.size); // This would be a new utility function.
    return (
      <FilesListItemHolder key={file.name}>
        <FilesListItem>
          <FileIcon fileType={file.type} />{" "}
          {/* New component to render file type icons */}
          <div className="info">
            <span className="title">{file.name}</span>
            <span>{readableSize}</span>
          </div>
        </FilesListItem>
        <FilesListItemClose
          onClick={() => setFiles((files) => files.filter((f) => f !== file))}
        >
          <XCircleIcon />
        </FilesListItemClose>
      </FilesListItemHolder>
    );
  });

  useEffect(() => {
    setExceedFileLimit(false);
    if (dropzone.fileRejections.length > 0) {
      setRejectedFiles(true);
    } else {
      setRejectedFiles(false);
    }

    if (
      files.length + dropzone.acceptedFiles.length >
      DROPZONE_CONFIG.maxFiles
    ) {
      setExceedFileLimit(true);
      return;
    }

    if (dropzone.acceptedFiles.length > 0) {
      const acceptedFileIds = dropzone.acceptedFiles.map(fileId);
      setFiles((files) => [
        ...files.filter((f) => !acceptedFileIds.includes(fileId(f))),
        ...dropzone.acceptedFiles,
      ]);
    }
  }, [dropzone.acceptedFiles, setFiles]);

  return (
    <TypingAreaHolder>
      <>
        {exceedFileLimit && (
          <Alert
            variant="filled"
            severity="error"
            className="break-all mt-2 mb-2"
            onClose={() => setExceedFileLimit(false)}
          >
            You can only upload up to {DROPZONE_CONFIG.maxFiles} files.
          </Alert>
        )}

        {dropzone.fileRejections.length > 0 && (
          <>
            {dropzone.fileRejections.map((reject, i) => (
              <Alert
                variant="filled"
                severity="error"
                className="break-all mt-2 mb-2"
                key={i}
              >
                {reject.file.name} - {reject.errors[0].message}
              </Alert>
            ))}
          </>
        )}
      </>

      {files.length > 0 ? (
        <UploadFileLists className={cn(isInflight && "not-allow")}>
          {FilesToShow}
        </UploadFileLists>
      ) : null}
      <TypingForm
        onSubmit={async (e) => {
          e.preventDefault();
          if (isInflight) return;
          const form = e.target as HTMLFormElement;
          const message = form.message.value;
          if (!message) return;
          setInflight(true);
          await props.onSubmit({ message, files });
          setInflight(false);
          form.message.value = "";
          setFiles([]);
        }}
      >
        {" "}
        <InputHolder className={cn(isInflight && "not-allow")}>
          {/* <ChatIconHolder>
            <ChatBubbleLeftIcon />
          </ChatIconHolder> */}
          <TypingFormInput
            type="text"
            name="messsage"
            id="message"
            autoFocus
            autoComplete="off"
            placeholder="Ask something…"
            readOnly={isInflight}
          />
          {isDocumentRetrievalActive && (
            <FileUploadLink>
              <input {...dropzone.getInputProps()} />
              <AttachmentIcon onClick={dropzone.open} />
            </FileUploadLink>
          )}
        </InputHolder>
        <ButtonChatSubmit
          type="submit"
          disabled={(isInflight && !props.onInterrupt) || rejectedFiles}
          onClick={
            props.onInterrupt
              ? (e) => {
                  e.preventDefault();
                  props.onInterrupt?.();
                }
              : undefined
          }
          className={cn(
            isInflight && !props.onInterrupt && "opacity-50 cursor-not-allowed"
          )}
        >
          {props.onInterrupt ? <XCircleIcon /> : <PaperAirplaneIcon />}
          {isInflight ? (props.onInterrupt ? "Cancel" : "Sending...") : "Send"}
        </ButtonChatSubmit>
      </TypingForm>
    </TypingAreaHolder>
  );
}
