import { DateIntervalContainer, HeaderSkeleton, TitleSkeleton } from "./style";

const VeticalSessionsSkeleton = () => {
  return (
    <>
      {[3, 7].map((rowNums, index) => (
        <DateIntervalContainer key={index}>
          <HeaderSkeleton min={30} max={40} />
          {Array.from({ length: rowNums }, (_, index) => (
            <TitleSkeleton key={index} min={40} max={101} />
          ))}
        </DateIntervalContainer>
      ))}
    </>
  );
};

export default VeticalSessionsSkeleton;
