import { useCallback, useEffect, useReducer } from "react";
import orderBy from "lodash/orderBy";
import { Chat } from "../types";
import bBaseApi from "../api/bBaseApi";
import { useNavigate, useParams } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BB_ASSISTANT_URL;

export interface ChatListProps {
  chats: Chat[] | null;
  createChat: (name: string, assistant_id: string) => Promise<Chat>;
  updateChat: (
    name: string,
    thread_id: string,
    assistant_id: string | null
  ) => Promise<Chat>;
  deleteChat: (thread_id: string) => Promise<void>;
}

function chatsReducer(
  state: Chat[] | null,
  action: Chat | Chat[]
): Chat[] | null {
  state = state ?? [];
  if (!Array.isArray(action)) {
    const newChat = action;
    action = [
      ...state.filter((c) => c.thread_id !== newChat.thread_id),
      newChat,
    ];
  }
  return orderBy(action, "updated_at", "desc");
}

export function useChatList(): ChatListProps {
  const { workspaceId, chatId } = useParams();
  const navigate = useNavigate();
  const [chats, setChats] = useReducer(chatsReducer, null);

  useEffect(() => {
    async function fetchChats() {
      const chats = await bBaseApi.get(`/threads/`).then((r) => r.data);
      setChats(chats);
    }

    fetchChats();
  }, []);

  const createChat = useCallback(async (name: string, assistant_id: string) => {
    const response = await bBaseApi.post(
      `/threads`,
      JSON.stringify({ assistant_id, name })
    );
    const saved = await response.data;
    setChats(saved);
    return saved;
  }, []);

  const updateChat = useCallback(
    async (thread_id: string, name: string, assistant_id: string | null) => {
      const response = await bBaseApi.put(
        `/threads/${thread_id}`,
        JSON.stringify({ assistant_id, name })
      );
      const saved = await response.data;
      setChats(saved);
      return saved;
    },
    []
  );

  const deleteChat = useCallback(
    async (thread_id: string) => {
      await bBaseApi.delete(`${baseUrl}/threads/${thread_id}`);
      setChats((chats || []).filter((c: Chat) => c.thread_id !== thread_id));
      if (chatId === thread_id) {
        navigate(`/workspace/${workspaceId}/brightbot-studio`);
      }
    },
    [chatId, chats, navigate, workspaceId]
  );

  return {
    chats,
    createChat,
    updateChat,
    deleteChat,
  };
}

export function useWorkspaceChatList(): ChatListProps {
  const { workspaceId } = useParams();
  const [chats, setChats] = useReducer(chatsReducer, null);

  useEffect(() => {
    async function fetchChats() {
      const chats = await bBaseApi
        .get(`/assistants/workspace/${workspaceId}`)
        .then((r) => r.data);
      setChats(chats);
    }

    fetchChats();
  }, [workspaceId]);

  const createChat = useCallback(async (name: string, assistant_id: string) => {
    const response = await bBaseApi.post(
      `/threads`,
      JSON.stringify({ assistant_id, name })
    );
    const saved = await response.data;
    setChats(saved);
    return saved;
  }, []);

  const updateChat = useCallback(
    async (thread_id: string, name: string, assistant_id: string | null) => {
      const response = await bBaseApi.put(
        `/threads/${thread_id}`,
        JSON.stringify({ assistant_id, name })
      );
      const saved = await response.data;
      setChats(saved);
      return saved;
    },
    []
  );

  const deleteChat = useCallback(
    async (thread_id: string) => {
      await bBaseApi.delete(`${baseUrl}/threads/${thread_id}`);
      setChats((chats || []).filter((c: Chat) => c.thread_id !== thread_id));
    },
    [chats]
  );

  return {
    chats,
    createChat,
    updateChat,
    deleteChat,
  };
}
