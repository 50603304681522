import { styled } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const TitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  paddingInline: ".5rem",
  borderBottom: "1px solid #e0e0e0",
  paddingBottom: ".5rem",
});

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)({
  transition: "rotate .5s ease",
});
