import { Box, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const StyledIFrameContainer = styled(Box)(() => ({
  marginTop: "2rem",
  maxHeight: "25vh",
}));

export const StyledIframe = styled("iframe")(() => ({
  width: "100%",
  height: "100vh",
  border: "none",
}));
