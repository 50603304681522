import { CircularProgress } from "@mui/material";
import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";
import {
  AvatarWrapper,
  ChatRcvBotDescription,
  ChatRcvBotWidgetWrapper,
} from "../style";

export const ChatRcvBotWidget = ({ avatar, children, streaming }: any) => {
  return (
    <ChatRcvBotWidgetWrapper
      sx={{ position: "relative" }}
      className="ChatRcvBotWidget"
    >
      {streaming && (
        <CircularProgress
          size={38} // Adjust size
          thickness={4} // Adjust thickness
          sx={{
            position: "absolute",
            top: "-3px",
            left: "-3px",
          }}
        />
      )}
      <AvatarWrapper>
        {avatar && <img src={avatar} alt="" />}
        <BrightBotSmileIcon />
      </AvatarWrapper>
      <ChatRcvBotDescription>
        <div style={{ whiteSpace: "pre-wrap" }}>{children}</div>
      </ChatRcvBotDescription>
    </ChatRcvBotWidgetWrapper>
  );
};
