import { ItemContentContainer, ItemWrapper } from "../../style";
import ReactMarkdown from "react-markdown";
import { Box } from "@mui/material";
import { reactMarkdownComp } from "../../../../Constants/reactMarkdownComp";
import rehypeRaw from "rehype-react";
import remarkGfm from "remark-gfm";

type Props = {
  reasonings: any[];
};

const ReasoningsWrapper = ({ reasonings }: Props) => {
  return (
    <>
      {reasonings
        ?.filter((reasoning) => typeof reasoning === "string")
        .map((reasoning, index) => {
          return (
            <ItemWrapper key={`reasoning-${index}`}>
              <ItemContentContainer>
                <Box>
                  <ReactMarkdown
                    children={reasoning}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw] as any}
                    components={reactMarkdownComp}
                  />
                </Box>
              </ItemContentContainer>
            </ItemWrapper>
          );
        })}
    </>
  );
};

export default ReasoningsWrapper;
