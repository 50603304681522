import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { CloseIcon } from "../../common/Icons/CloseIcon";
import { TextField } from "formik-mui";
import { useMutation, useQuery } from "@apollo/client";
import { GetResourcesDocument, MoveResourceDocument } from "../../generated";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Label } from "../../common/Label/Label";
import { FolderIcon } from "../../common/Icons/FolderIcon";
import { PapersListIcon } from "../../common/Icons/PapersListIcon";
import { CheckMarkIcon } from "../../common/Icons/CheckMarkIcon";
import { CreateFolder } from "./CreateFolder";
import { ButtonDefault, ButtonPrimary } from "./style";

export const Move = ({ name, id, onClose }: any) => {
  const { workspaceId, folderId } = useParams();
  const { palette } = useTheme();
  const [createFolder, setCreateFolder] = useState(false);

  const [selected, setSelected] = useState<string>();

  const [inside, setInside] = useState<string>();

  const { data: parentInfo, loading: resourceLoading } = useQuery(
    GetResourcesDocument,
    {
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
        resourceFilter: {
          id: folderId || "",
        },
      },
      skip: !folderId,
    }
  );

  const { data, loading } = useQuery(GetResourcesDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      resourceFilter: {
        folderId: inside || "",
      },
    },
  });

  const folders = useMemo(
    () =>
      data?.workspace?.resources?.filter((res: any) => res?.isFolder === true),
    [data]
  );

  const [move] = useMutation(MoveResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });

  const moveFile = () => {
    move({
      variables: {
        input: {
          resourceId: id,
          parentId: selected || "",
          workspaceId: workspaceId || "",
        },
      },
    })
      .then((res) => {
        toast.success("File moved");
        onClose();
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      {createFolder ? (
        <CreateFolder onClose={() => onClose()} />
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "720px",
            position: "absolute",
            left: "50%",
            top: "50%",
            backgroundColor: palette.common.white,
            transform: "translate(-50%, -50%)",
            borderRadius: "11px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "15px",
              padding: "24px",
              borderBottom: `1px solid ${palette.action.disabledBackground}`,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "24px",
                lineHeight: "28px",
              }}
            >
              Move {name}
            </Typography>
            <Button
              onClick={() => onClose()}
              type="button"
              sx={{
                minWidth: "1px",
                padding: "0",
                color: palette.gray.dark,

                svg: {
                  width: "20px",
                  height: "20px",
                },
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          {parentInfo && (
            <Box
              sx={{
                padding: "24px 24px 0",
              }}
            >
              <Label
                value="Current Folder:"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  mb: 3,
                }}
              />
              <Box
                sx={{
                  borderRadius: "4px",
                  padding: "16px",
                  background: palette.primary.lighter,
                  color: palette.primary.main,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FolderIcon />
                <Typography
                  sx={{
                    color: palette.common.black,
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    m: 1,
                    ml: 2,
                  }}
                >
                  {parentInfo?.workspace?.resources?.[0]?.name}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              padding: "24px",
              maxHeight: "calc(100vh - 200px)",
              overflowY: "auto",
            }}
          >
            <Label
              value="Move item into.."
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                mb: 4,
              }}
            />
            <Box
              sx={{
                borderRadius: "4px",
                padding: "16px",
                color: palette.common.black,
                display: "flex",
                alignItems: "center",
                borderBottom: `1px solid ${palette.action.disabledBackground}`,
              }}
            >
              <PapersListIcon />
              <Typography
                sx={{
                  color: palette.common.black,
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  m: 1,
                  ml: 2,
                }}
              >
                Resources
              </Typography>
            </Box>
            {folders?.map((folder) => (
              <Box
                sx={{
                  borderBottom: `1px solid ${palette.action.disabledBackground}`,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setSelected(folder?.id)}
                  onDoubleClick={() => {
                    setSelected("");
                    setInside(folder?.id);
                  }}
                  className={selected === folder?.id ? "selected" : ""}
                  sx={{
                    border: "none",
                    padding: "13px 32px 13px 16px",
                    color: palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",

                    "&:active, &:focus": {
                      background: `none !important`,
                      border: "none !important",
                      boxShadow: "none !important",
                    },

                    "&:hover, &.selected": {
                      background: `${palette.primary.lighter} !important`,
                      border: "none !important",
                      boxShadow: "none !important",
                    },
                  }}
                >
                  <FolderIcon />
                  <Typography
                    sx={{
                      color: palette.common.black,
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      m: 1,
                      ml: 2,
                    }}
                  >
                    {folder?.name}
                  </Typography>
                  {selected === folder?.id && (
                    <CheckMarkIcon
                      sx={{
                        position: "absolute",
                        right: "8px",
                        top: "50%",
                        marginTop: "-10px",
                        fontSize: "20px",
                        lineHeight: "1",
                      }}
                    />
                  )}
                </Button>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              boxShadow: `0 -3px 5px ${palette.gray.main}`,
              padding: "24px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <ButtonPrimary type="button" onClick={() => setCreateFolder(true)}>
              Create new folder
            </ButtonPrimary>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <ButtonDefault onClick={() => onClose()} type="button">
                Cancel
              </ButtonDefault>
              <ButtonPrimary
                onClick={() => moveFile()}
                disabled={!selected || loading}
                type="button"
              >
                Move
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
