import { create } from "zustand";

interface BrightbotStore {
  assistants: any[];
  activeAssistant: any;
  setAssistants: (assistants: any[]) => void;
  setActiveAssistant: (assistant: any) => void;
}

export const useBrightbotStore = create<BrightbotStore>((set) => ({
  assistants: [],
  activeAssistant: "",
  setAssistants: (assistants) => {
    set({
      assistants,
    });
  },
  setActiveAssistant: (assistant) => {
    set({
      activeAssistant: assistant,
    });
  },
}));
