import { agentTypes } from "../../types";
import AnalysisBlock from "./components/AnalysisBlock";
import DBTBlock from "./components/DBTBlock";
import RetrevialBlock from "./components/RetrievalBlock";
import VisualizationBlock from "./components/VisualizationBlock";

type Props = {
  outputs: any[];
  closedTabs: number[];
  handleCollapse: (index: number) => () => void;
};

const OutputsWrapper = ({ outputs, closedTabs, handleCollapse }: Props) => {
  return (
    <>
      {outputs?.map((jsonOutput, index) => {
        if (Array.isArray(jsonOutput)) return;

        if (jsonOutput?.agent === agentTypes.dataVisualization)
          return (
            <VisualizationBlock
              key={`output-${index}`}
              output={jsonOutput}
              index={index}
              isClosed={closedTabs.includes(index)}
              handleCollapse={handleCollapse}
            />
          );

        if (jsonOutput?.agent === agentTypes.dbt)
          return (
            <DBTBlock
              key={`output-${index}`}
              output={jsonOutput}
              index={index}
              isClosed={closedTabs.includes(index)}
              handleCollapse={handleCollapse}
            />
          );

        if (jsonOutput?.agent === agentTypes.analyst)
          return (
            <AnalysisBlock
              key={`output-${index}`}
              output={jsonOutput}
              index={index}
              isClosed={closedTabs.includes(index)}
              handleCollapse={handleCollapse}
            />
          );

        const output = JSON.parse(jsonOutput);
        return (
          <RetrevialBlock
            key={`output-${index}`}
            output={output}
            index={index}
            isClosed={closedTabs.includes(index)}
            handleCollapse={handleCollapse}
          />
        );
      })}
    </>
  );
};

export default OutputsWrapper;
