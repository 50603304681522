import { Typography, useTheme } from "@mui/material";
import { StyledKeyboardArrowDownIcon, TitleContainer } from "./style";
import { AnimationStylesTypes } from "../../types";

type Props = {
  title: string;
  handleCollapse: (index: number) => () => void;
  index: number;
  animationStyles: AnimationStylesTypes;
};

const TitleWrapper = ({
  title,
  handleCollapse,
  index,
  animationStyles,
}: Props) => {
  const { palette } = useTheme();

  return (
    <TitleContainer sx={animationStyles.displayTitleBorder}>
      <Typography color={palette.gray.dark} variant="subtitle1">
        {title} Agent
      </Typography>
      <StyledKeyboardArrowDownIcon
        sx={animationStyles.rotateIcon}
        onClick={handleCollapse(index)}
      />
    </TitleContainer>
  );
};

export default TitleWrapper;
