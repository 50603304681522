import { Skeleton, SkeletonProps } from "@mui/material";
import { useMemo } from "react";
import { randomNumGenerateUtil } from "../utils/randomNumGenerate";

type Props = { min?: number; max?: number } & SkeletonProps;

const SkeletonComp = ({ min, max, ...props }: Props) => {
  const skeletonWidth = useMemo(
    () => min && max && `${randomNumGenerateUtil(min, max)}%`,
    []
  );
  return <Skeleton width={skeletonWidth} {...props} />;
};

export default SkeletonComp;
